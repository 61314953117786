import { SearchIndex } from './searchIndex';
import { DocumentLog } from './documentLog';
import { IObjectMap } from '../interface';

export interface GroupMember {
    id: string;
    picture: string;
    firstName: string;
    lastName: string;
    requestId?: string;
}

export class Group {
    id?: string;
    name: string;
    description: string;
    workingAreaId: string;
    serviceId: string;
    coordinator: GroupMember;
    members: IObjectMap<GroupMember>;
    log?: DocumentLog;
    searchIndex: SearchIndex;

    constructor() {
        this.members = {};
        this.searchIndex = {
            index: {},
            properties: [
                'name',
                'coordinator.firstName',
                'coordinator.lastName'
            ]
        };
    }
}
