<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{user?.fullname}}</div>
    <div class="modal_subtitle">{{labels.notes_and_activities}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <app-global-activity-list [role]="data.role" [editMode]="true" [user]="user" [resources]="resourcesMap"
      [lastReadDate]="lastReadDate" (onAverageSumAmount)="averageSumAmount = $event"
      (onActivities)="activitiesLoaded()" [request]="data.request">
    </app-global-activity-list>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <span>{{averageSumAmount | currency:'€'}}</span>
    </div>

    <div *ngIf="resourcesMap">
      <button mat-button (click)="addActivity()">{{labels.btn_add_contact_moment}}</button>
    </div>
  </div>
</app-modal>