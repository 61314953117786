import { DocumentLog } from './documentLog';
import { IObjectMap } from '../interface';
import { Mailbox } from './service';

export interface RedirectToInstitution {
  name: string;
  id: string;
  defaultText?: string;
}

export interface IWorkingAreaSettings {
  referredByValues?: string[];
  redirectedToValues?: string[];
  redirectedToInstitution?: RedirectToInstitution[];
  followupStepsValues?: string[];
  serviceCatalog?: boolean;
  requestKind: string[];
}

export class WorkingArea {
  id?: string;
  name: string;
  description: string;
  professionals: IObjectMap<boolean>;
  services: IObjectMap<boolean>;
  active: boolean;
  log: DocumentLog;
  connectedCustomForms: IObjectMap<true>;
  settings: IWorkingAreaSettings;
  email: Mailbox;
  sharedMailboxFooter: string;
  
  constructor() {
    this.name = '';
    this.active = true;
    this.professionals = {};
    this.services = {};
    this.connectedCustomForms = {};
    // this.log = JSON.parse(JSON.stringify(new DocumentLog()));
    this.settings = {
      referredByValues: [],
      redirectedToValues: [],
      followupStepsValues: [],
      requestKind: [],
    };
  }
}
