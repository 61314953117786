import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Activity, ActivityKindTypes, ActivityReply, ActivityAttachment, User, Role, Request } from '@shared/model';
import { IObjectMap } from '@shared/interface';
import { UserService } from '@core/services/user.service';
import { LabelService } from '@core/services/labels.service';
import { UtilitiesService } from '@core/services/utilities.service';
import { UserPromptsService } from '@core/services/user-prompt.service';
import { take } from 'rxjs/operators';
import { RoleTypes } from '@shared/enum';
import { ObjectMap } from '../../../../../functions/src/interfaces';
import { ActivityItem } from '@core/services/activity.service';
import { isAfter, isValid } from 'date-fns';
// import { EditUserComponent } from '@users/components/edit-user/edit-user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveActivityComponent } from '../move-activity/move-activity.component';
import { RequestService } from '@core/services';

@Component({
  selector: 'app-activity-item-list',
  templateUrl: './activity-item-list.component.html',
  styleUrls: ['./activity-item-list.component.scss']
})
export class ActivityItemListComponent implements OnInit, OnChanges {
  @Input() activities: ActivityItem[];
  @Input() users: IObjectMap<User>;
  @Input() editMode: boolean;
  @Input() lastReadDate = new Date(0);
  @Input() customerSelectable: boolean;

  @Output() onDeleteActivity = new EventEmitter<Activity>();
  @Output() onEditActivity = new EventEmitter<Activity>();
  @Output() onDeleteActivityReply = new EventEmitter<{ reply: ActivityReply, item: Activity }>();
  @Output() onAddReply = new EventEmitter<Activity>();
  @Output() onEditReply = new EventEmitter<{ reply: ActivityReply; item: Activity; }>();

  public currentUserId: string;
  public labels = this.labelService.defaultProvider();
  public activityKinds = ActivityKindTypes;
  public canReply: boolean;
  public orgAdmin: boolean;
  public timeAgo: ObjectMap<string> = {};
  public currentUser: User;
  public hasOrgAdminRole: Boolean;
  private userRole: Role;
  private movePayload: { requests: Request[], activity: Activity, customerName: string } = {} as any;
  protected rowOptionBtn: { disabled: boolean; label: string } = {disabled: false, label: ''};

  constructor(
    private labelService: LabelService,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private userPromptsService: UserPromptsService,
    private router: Router,
    private route: ActivatedRoute,
    private requestService: RequestService
  ) { }

  async ngOnInit() {
    this.currentUserId = this.userService.getCurrentUserId();
    this.canReply = this.utilitiesService.rolesMatch(RoleTypes.professional, RoleTypes.coordinator);
    this.orgAdmin = this.utilitiesService.rolesMatch(RoleTypes.orgAdmin);
    this.currentUser = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
    this.userRole = this.userService.getCurrentUserRole();
    this.hasOrgAdminRole = this.currentUser.isOrgAdmin ? this.currentUser.isOrgAdmin : false;

    this.labelService.getLabels$('app-activity-item-list').subscribe(lbs => this.labels = lbs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.activities)) {
      (this.activities || []).forEach(a => {
        (a.activity.replies || []).forEach(r => {
          this.timeAgo[r.id] = this.utilitiesService.timeAgo(r.log.createdAt);
        });
      });
    }
  }

  public isUnRead(date: Date): boolean {
    return isAfter(date, this.lastReadDate);
  }

  public addReply(item: Activity) {
    this.onAddReply.emit(item);
  }

  public editReply(reply: ActivityReply, item: Activity) {
    this.onEditReply.emit({ reply, item });
  }

  public async openUserModal(item: ActivityItem) {
    const activity = item.activity;
    activity.requestId ? this.router.navigate([`../../requests/detail`, activity.requestId], { relativeTo: this.route })
      : this.router.navigate(['../../users', 'detail', activity.userId], { relativeTo: this.route });
  }

  public deleteActivityReply(reply: ActivityReply, item: Activity) {
    this.onDeleteActivityReply.emit({ reply, item });
  }

  public editActivity(card: Activity): void {
    this.onEditActivity.emit(card);
  }

  public openAttachment(attach: ActivityAttachment): void {
    window.open(attach.url);
  }

  public deleteActivity(card: Activity): void {
    this.onDeleteActivity.emit(card);
  }

  public trackByFn(index: number, item: { id: string }): string {
    return item.id + '_' + index;
  }

  public getUser(userId: string): User {
    return this.users[userId];
  }

  public getLocaleDate(d: Date, weekday?: boolean): string {
    const date = isValid(new Date(d.toString())) ? new Date(d.toString()) : null;

    if (date) {
      return weekday
        ? date.toLocaleDateString(undefined, { weekday: 'short' })
        : date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
    } else {
      return '';
    }
  }

  protected rowOptionClicked(row: ActivityItem) {
    this.fetchCustomerRequests(row);
  }

  private async fetchCustomerRequests(row: ActivityItem) {
    let promise: Promise<Request[]>;
    const activity = row.activity;
    this.movePayload.activity = activity;
    const customer = await this.userService.getUserById(activity.userId).pipe(take(1)).toPromise();
    this.movePayload.customerName = customer.fullname
    if (this.userRole.roleType == RoleTypes.coordinator) {
      promise = this.requestService.getCustomerRequests(activity.userId, this.userRole.serviceId);
    } else if (this.userRole.roleType == RoleTypes.professional) {
      promise = this.requestService.getCustomerRequests(activity.userId, null, this.userRole.workingAreaId);
    }
    const requests = await promise;
    this.movePayload.requests = requests;
    this.rowOptionBtn.label = requests?.length ? `${this.labels.move} (${requests.length})` : this.labels.move;
    if (requests?.length) {
      if (requests.length == 1 && requests[0].id == activity.requestId) {
        this.rowOptionBtn.disabled = true;
        return;
      }
      this.rowOptionBtn.disabled = false;
    } else {
      this.rowOptionBtn.disabled = true;
    }
  }

  protected moveActivity() {
    this.userPromptsService.showDialogue(MoveActivityComponent, this.movePayload, null, null, { width: '500px' });
  }
}
