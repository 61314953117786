import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, map as _map, sortBy } from 'lodash';
import { User, Role, UserConnectedServiceAndWorkingArea, Activity, Request } from '@shared/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  ActivityService,
  LabelService,
  OrgServiceService,
  UserPromptsService,
  UserService
} from '@core/services';
import { NgSub } from 'ng-sub';
import { IObjectMap } from '@models/interface';
import { ActivityItemDialogFormComponent } from '../activity-item-dialog-form/activity-item-dialog-form.component';
import { take, takeUntil } from 'rxjs/operators';
import { EmailService } from '@core/services/email.service';

interface ActivityDialogInputData {
  user: User;
  role: Role;
  resources: IObjectMap<UserConnectedServiceAndWorkingArea>;
  request?: Request;
}

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
})
export class ActivityDialogComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public user: User;
  public lastReadDate = new Date(0);
  public resourcesMap: IObjectMap<UserConnectedServiceAndWorkingArea> = {};
  public averageSumAmount = 0;
  public budgetActivities: Activity[];

  private sub = new NgSub();
  private subs = new NgSub();
  private currentUser: User;
  private seen = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ActivityDialogInputData,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ActivityDialogComponent>,
    private labelService: LabelService,
    private activityService: ActivityService,
    private userService: UserService,
    private userPromptsService: UserPromptsService,
    private orgServiceService: OrgServiceService,
    private emailService: EmailService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  ngOnInit() {
    this.user = this.data.user;
    this.resourcesMap = this.data.resources;
    this.dialogRef.disableClose = true;

    this.labelService.getLabels('app-activity-dialog').then(r => this.labels = r.data);

    const curUserId = this.userService.getCurrentUserId();
    this.user.activityLastSeenByUsers = this.user?.activityLastSeenByUsers || {};

    if (this.user?.activityLastSeenByUsers[curUserId]) {
      this.lastReadDate = this.user.activityLastSeenByUsers[curUserId];
    }

    this.userService.getCurrentUser().pipe(takeUntil(this.sub)).subscribe(u => {
      this.currentUser = u;

    });
  }

  public activitiesLoaded(): void {
    if (!this.seen) {
      this.seen = true;
      setTimeout(() => {
        this.activityService.updateLastSeenActivitiesForUser(this.user.id, new Date());
      }, 3000);
    }
  }

  public addActivity(): void {
    const services = sortBy(this.resourcesMap[this.data.role.workingAreaId].services, 'name');
    if (!services.length) {
      console.error('addActivity:some logic error', { this: this });
      return;
    }

    const newActivity = new Activity();
    newActivity.userId = this.data.user.id;
    newActivity.orgId = localStorage.getItem('user_organization');
    newActivity.rates = this.currentUser.employeeDetails.rates || null;
    newActivity.budgetCode = this.currentUser.employeeDetails.defaultBudgetCode || null;

    const specialMUnicipality: string[] = ['GM0448', 'GM0441', 'GM0400'];

    if (this.user.area && specialMUnicipality.indexOf(this.user.area.municipality.code) > -1) {

      newActivity.rates = { internal: 0, external: 72.60 },
        newActivity.budgetCode = '45A53'
    } else {
      newActivity.rates = this.currentUser.employeeDetails.rates || null;
      newActivity.budgetCode = this.currentUser.employeeDetails.defaultBudgetCode || null;
    };


    if (this.data.request && this.data.request.id) {
      newActivity.requestId = this.data.request.id;
    }

    const payload = {
      role: cloneDeep(this.data.role),
      services: services,
      activity: newActivity,
      request: cloneDeep(this.data.request)
    };

    this.userPromptsService.showDialogue(
      ActivityItemDialogFormComponent,
      payload,
      (r: Activity) => {
        if (r) {
          r.workareaId = this.data.role.workingAreaId;

          const creatorId = this.data.user.id;
          this.userService
            .addActivityToUser(creatorId, r).then(() => {
              this.userPromptsService.showToast(
                (<Function>this.labels._translate)('activity_added', { title: r.title })
              );

              // send email for new activity
              this.orgServiceService.getServiceById(r.serviceId).pipe(take(1)).subscribe(s => {
                this.emailService.sendEmailForNewActivity(this.data.user, r, s).catch();
              });
            });
        }
      }, true
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();

    this.subs.unsubscribe();
  }
}
