<div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 30px;">
  <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-card style="min-height: 245px;" fxLayout="column">
        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="stretch" *ngIf="customer.address as addr">
              <div>{{addr.street}} {{addr.number}} {{addr.letter}}</div>
              <div>{{addr.postalcode}} {{addr.city}}</div>
              <div *ngIf="customer.area">{{customer.area?.district.text}} - {{customer.area?.neighbourhood.text}}</div>
              <div style="margin-top: 15px;">
                <span *ngIf="customer.gender && customer.gender != 'unknown'">{{ labels[customer.gender] }} </span>
                <span *ngIf="age() > 0"></span> <span *ngIf="customer.birthday">
                  ({{age()}})</span>
              </div>
              <div style="margin-top: 15px;" *ngIf="customer.userPartner && customer.userPartner.partnerRegistration">
                {{customer.userPartner.firstname}} {{customer.userPartner.lastname}} ({{labels.partner}})</div>
              <div *ngIf="customer.userContactPerson && customer.userContactPerson.firstName">
                {{customer.userContactPerson.firstName}} {{customer.userContactPerson.lastName}}
                {{customer.userContactPerson.socialRelationKind ?
                '('+labels[socialKind[customer.userContactPerson.socialRelationKind]]+')' : ''}}
                <mat-icon color="accent" [inline]="true" style="font-size: 18px;"
                  (click)="openContactPersonDetailDialog('userContactPerson')">info_outline</mat-icon>
              </div>
              <div *ngIf="customer.userContactPerson2 && customer.userContactPerson2.firstName">
                {{customer.userContactPerson2.firstName}} {{customer.userContactPerson2.lastName}}
                {{customer.userContactPerson2.socialRelationKind ?
                '('+labels[socialKind[customer.userContactPerson2.socialRelationKind]]+')' : ''}}
                <mat-icon color="accent" [inline]="true" style="font-size: 18px;"
                (click)="openContactPersonDetailDialog('userContactPerson2')">info_outline</mat-icon>
            </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end">
              <thumbnail class="cursor_pointer" (click)="openImageModal(customer.picture)" height="60px" width="60px"
                [image]="customer.picture"></thumbnail>
              <span class="profile_pix_icon">
                <mat-icon [inline]="true" (click)="updateProfilePicture()">
                  edit
                </mat-icon>
              </span>
            </div>
          </div>


          <div style="padding-top: 10px;">{{customer.usernote}}</div>
        </mat-card-content>
        <div fxFlex="stretch"></div>

        <mat-card-footer fxLayout="row" fxLayoutAlign="space-between" style="padding: 0 10px 10px;">
          <button mat-icon-button color="primary" (click)="openGoogleMaps()" [disabled]="!canOpenGoogleMaps()">
            <mat-icon>location_on</mat-icon>
          </button>
          <button style="margin-top: auto;" mat-button color="primary" (click)="openEditUser()">{{labels.edit}}</button>
        </mat-card-footer>
      </mat-card>
    </div>

    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-card style="min-height: 245px;" fxLayout="column">
        <mat-card-content>
          <form [formGroup]="personInfoForm" fxLayout="column" fxLayoutGap="10px" role="form" *ngIf="personInfoForm">
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width" *ngIf="!editPersonalInfo">
                <mat-label>{{labels.phone}}</mat-label>
                <mat-hint>{{customer.phoneRemark}}</mat-hint>
                <div fxLayout="row">
                  <input [readonly]="true" autocomplete="off" matInput type="text" formControlName="phone">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;" matSuffix *ngIf="customer.phone"
                    class="cursor_pointer" (click)="copyToClipboard(customer.phone || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width" *ngIf="!editPersonalInfo">
                <mat-label>{{labels.phone2}}</mat-label>
                <mat-hint>{{customer.phone2Remark}}</mat-hint>
                <div fxLayout="row">
                  <input [readonly]="true" autocomplete="off" matInput type="text" formControlName="phone2">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;" *ngIf="customer.phone2" matSuffix
                    class="cursor_pointer" (click)="copyToClipboard(customer.phone2 || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>

              <div *ngIf="editPersonalInfo" fxLayout="column" style="width: 100%;">
                <div>
                  <app-phone-input [value]="personInfoForm.value.phone" [placeholder]="labels.phone"
                    (update)="updatePhoneField(personInfoForm, 'phone', $event)">
                  </app-phone-input>
                </div>

                <div>
                  <app-phone-input [value]="personInfoForm.value.phone2" [placeholder]="labels.phone2"
                    (update)="updatePhoneField(personInfoForm, 'phone2', $event)">
                  </app-phone-input>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" *ngIf="editPersonalInfo">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.phone_remark}}</mat-label>
                <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="text"
                  formControlName="phoneRemark">
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width" *ngIf="editPersonalInfo">
                <mat-label>{{labels.phone2_remark}}</mat-label>
                <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="text"
                  formControlName="phone2Remark">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email}}</mat-label>
                <mat-hint *ngIf="!editPersonalInfo">{{customer.emailRemark}}</mat-hint>
                <div fxLayout="row">
                  <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="email"
                    formControlName="email">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;" matSuffix
                    *ngIf="!editPersonalInfo && customer.email " class="cursor_pointer"
                    (click)="copyToClipboard(customer.email || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email2}}</mat-label>
                <mat-hint *ngIf="!editPersonalInfo">{{customer.email2Remark}}</mat-hint>
                <div fxLayout="row">
                  <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="email"
                    formControlName="email2">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;"
                    *ngIf="!editPersonalInfo && customer.email2" matSuffix class="cursor_pointer"
                    (click)="copyToClipboard(customer.email2 || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" *ngIf="editPersonalInfo">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email_remark}}</mat-label>
                <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="text"
                  formControlName="emailRemark">
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email2_remark}}</mat-label>
                <input [readonly]="!editPersonalInfo" autocomplete="off" matInput type="text"
                  formControlName="email2Remark">
              </mat-form-field>
            </div>
          </form>
        </mat-card-content>
        <div fxFlex="stretch"></div>


        <mat-card-footer fxLayout="row" fxLayoutAlign="space-between" style="padding: 0 10px 10px; margin-top: auto;">
          <button mat-icon-button color="primary" (click)="openAvailabilityDialog()">
            <mat-icon>event_available</mat-icon>
          </button>
          <div fxLayout="row" fxLayoutGap="15px" style="margin-top: auto;">
            <button mat-button color="primary" *ngIf="!editPersonalInfo"
              (click)="editPersonalInfo = true">{{labels.edit}}</button>
            <button mat-button color="primary" *ngIf="editPersonalInfo"
              (click)="setupPersonalInfoForm()">{{labels.cancel}}</button>
            <button mat-raised-button
              [disabled]="personInfoForm?.invalid || personInfoForm?.pristine || savingPersonalForm" color="primary"
              *ngIf="editPersonalInfo" (click)="savePersonlInfoForm()">{{labels.save}}</button>
          </div>
        </mat-card-footer>


      </mat-card>
    </div>
  </div>

  <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-expansion-panel (opened)="isGetMailingList = true">
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.additional_information}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column">

          <form [formGroup]="extraInfoForm" fxLayout="column" fxLayoutGap="10px" role="form" *ngIf="extraInfoForm">

            <mat-form-field appearance="outline">
              <mat-label>{{labels.user_short_note}}</mat-label>
              <textarea matInput maxlength="100" cdkTextareaAutosize minRows="2" #input
                formControlName="usernote"></textarea>
              <mat-hint align="end">{{input.value.length}}/100</mat-hint>
            </mat-form-field>


            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.personal_number}}</mat-label>
                <input [readonly]="!editExtraInfo" autocomplete="off" matInput type="text"
                  formControlName="personalNumber">

                <mat-error *ngIf="extraInfoForm.get('personalNumber').errors?.wrongNumber">
                  {{extraInfoForm.get('personalNumber')?.errors?.wrongNumber}}
                </mat-error>

              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.code}}</mat-label>
                <input [readonly]="!editExtraInfo" autocomplete="off" matInput type="text" formControlName="code">
              </mat-form-field>
            </div>

            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.mailinglists}}</mat-label>
              <input *ngIf="!editExtraInfo" [readonly]="true" autocomplete="off" matInput type="text"
                formControlName="mailinglistSubscriptionsName">

              <mat-select multiple *ngIf="editExtraInfo" formControlName="mailinglistSubscriptions">
                <mat-option *ngFor="let m of mailinglists" [value]="m.id"
                  [disabled]="!m.userCanSubscribe || !loggedInUser.isOrgAdmin">
                  {{m.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>

          <!-- <div fxFlex="stretch"></div> -->
          <mat-card-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
            <button class="actions-button" mat-button color="primary" *ngIf="!editExtraInfo"
              (click)="editExtraInfo = true">{{labels.edit}}</button>
            <button mat-button color="primary" *ngIf="editExtraInfo"
              (click)="setupExtraInfoForm()">{{labels.cancel}}</button>
            <button mat-raised-button [disabled]="extraInfoForm?.invalid || extraInfoForm?.pristine || savingExtraForm"
              color="primary" *ngIf="editExtraInfo" (click)="saveExtraInfoForm()">{{labels.save}}</button>
          </mat-card-actions>
        </div>
      </mat-expansion-panel>
    </div>

    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.appointments}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column">
          <div class="content">
            <mat-chip-listbox>
              <mat-chip-option [matTooltip]="appointment.title" class="chip_style"
                *ngFor="let appointment of appointments" selected="true" [color]="'default'">
                <ng-container *ngIf="appointment.id">
                  <span class="chip_text">
                    {{ appointment.date | date:'short'}}
                  </span>
                  <mat-icon class="close" [matMenuTriggerFor]="menu">more_vert</mat-icon>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editAppointment(appointment)">
                      <mat-icon>edit</mat-icon>
                      <span>{{ labels.edit }}</span>
                    </button>
                    <button mat-menu-item (click)="deleteAppointment(appointment)">
                      <mat-icon>delete</mat-icon>
                      <span>{{ labels.delete }}</span>
                    </button>
                  </mat-menu>
                </ng-container>

                <ng-container *ngIf="!appointment.id">
                  {{labels.loading}}...
                </ng-container>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div fxLayout="row" fxLayoutAlign="end">
            <button (click)="newAppointment()" class="actions-button" mat-button color="primary">{{labels.add}}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>


  <div *ngIf="fromPage === 'userPage'" fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
    <div fxFlex="stretch">

      <mat-expansion-panel *ngIf="customer">
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.log_info}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column" class="settings_content">
          <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px;">
            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.created_at}}</div>
              <div>{{toDate(customer.log.createdAt)}}</div>
            </div>

            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.modified_at}}</div>
              <div>{{toDate(customer.log.updatedAt)}}</div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.created_by}}</div>
              <div>{{createdBy?.fullname}}</div>
            </div>

            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.modified_by}}</div>
              <div>{{updatedBy?.fullname}}</div>
            </div>
          </div>
        </div>

        <mat-card-actions>
          <div fxLayout="row" fxLayoutAlign="end">
            <button mat-icon-button (click)="openUsageDocModal()">
              <mat-icon>history</mat-icon>
            </button>
          </div>
        </mat-card-actions>
      </mat-expansion-panel>
    </div>
    <div fxFlex="stretch"></div>
  </div>
</div>