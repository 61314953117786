<div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 30px;">
    <div fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-card fxLayout="column" style="min-height: 160px;">
                <mat-card-header>
                    <mat-card-title>
                        <div class="card-title">{{labels.documents}}</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-progress-bar *ngIf="docLoading" mode="determinate" [value]="progress"></mat-progress-bar>
                <div class="content" *ngIf="docTableConfig?.data?.length">
                    <data-table [configOptions]="docTableConfig" (onSelectRow)="openDoc($event)"
                        (onSelectRowAction)="handleDocRowAction($event)">
                    </data-table>
                </div>
                <div fxFlex="stretch"></div>

                <mat-card-actions [fxLayoutAlign]="docTableConfig?.data?.length >= 3 ? 'space-between' : 'end'">
                    <button mat-button color="primary" *ngIf="docTableConfig?.data?.length >= 3"
                        (click)="openDocumentDialog()">{{labels.show_all + ' (' + docCount + ')'}}</button>
                    <button mat-button color="primary" (click)="selectFile()">{{labels.add}}</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-card fxLayout="column" style="min-height: 160px;">
                <mat-card-header>
                    <mat-card-title>
                        <div class="card-title">{{labels.forms}}</div>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div class="content" *ngIf="customForms && customForms.length">
                        <mat-chip-listbox>
                            <mat-chip-option *ngFor="let form of customForms" selected="true"
                                (click)="openCustomFormModal(form)" [color]="getCustomFormColor(form)">
                                {{form.label}}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </mat-card-content>

                <div fxFlex="stretch"></div>
            </mat-card>
        </div>
    </div>

    <div fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
        <div [style]="{width: isMobile ? '100%' : '49%'}">

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="card-title" style="margin-bottom: 0;">{{labels.network}}</div>
                </mat-expansion-panel-header>

                <div>
                    <ng-template matExpansionPanelContent>
                        <app-customer-network [customer]="customer"></app-customer-network>
                    </ng-template>
                </div>
            </mat-expansion-panel>
        </div>

        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="card-title" style="margin-bottom: 0;">{{labels.membership}}</div>
                </mat-expansion-panel-header>

                <form [formGroup]="memberShipForm" fxLayout="column" fxLayoutGap="10px" role="form"
                    *ngIf="memberShipForm">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-form-field appearance="outline" class="">
                            <mat-label>{{labels.kind}}</mat-label>
                            <input readonly *ngIf="!editMemberShip" autocomplete="off" matInput
                                [disabled]="org?.settings?.membershipSettings?.membershipKinds.length === 0"
                                type="text" formControlName="membershipKindsName">

                            <mat-select *ngIf="editMemberShip"
                                [disabled]="org.settings.membershipSettings.membershipKinds.length === 0"
                                formControlName="membershipKinds">
                                <mat-option [value]="null">{{labels.empty}} </mat-option>
                                <mat-option *ngFor="let value of org?.settings.membershipSettings.membershipKinds || []"
                                    [value]="value">
                                    {{ value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{labels.since}}</mat-label>
                            <input [readonly]="!editMemberShip || !memberShipForm.controls.membershipKinds.value"
                                autocomplete="off" matInput
                                [disabled]="memberShipForm.controls.membershipKinds.value ? false : true"
                                type="date" formControlName="memberSince">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline" class="">
                        <mat-label>{{labels.remark}}</mat-label>
                        <textarea [readonly]="!editMemberShip" matInput cdkTextareaAutosize
                            formControlName="remark"></textarea>
                    </mat-form-field>
                </form>

                <mat-card-actions align="end" style="margin: 0; padding: 0;">
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                        <button *ngIf="editMemberShip" mat-button color="primary"
                            (click)="onEditMemberShip()">{{labels.cancel}}</button>
                        <button *ngIf="editMemberShip" mat-raised-button color="primary"
                            (click)="onSaveMemberShip()">{{labels.save}}</button>
                        <button *ngIf="!editMemberShip" mat-button color="primary"
                            (click)="onEditMemberShip()">{{labels.edit}}</button>
                    </div>
                </mat-card-actions>
            </mat-expansion-panel>
        </div>
    </div>

    <!-- hidden elements -->
    <input #userImport type="file" (change)="onFileSelected($event)" [hidden]="true" />

</div>