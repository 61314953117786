import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelService, LocalStorageService, RequestService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { RoleTypes } from '@models/enum';
import { IObjectMap } from '@models/interface';
import { Request, Role, Service, User, WorkingArea } from '@models/model';
import { IDataTableConfig } from '@shared/components';
import { NewRequestFormComponent } from '@shared/entry-components/new-request-form/new-request-form.component';
import { isValid } from 'date-fns';
import { cloneDeep, debounce, flatten, isEmpty, isEqual, uniq, uniqBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-request-tab',
  templateUrl: './request-tab.component.html',
  styleUrls: ['./request-tab.component.scss']
})
export class RequestTabComponent implements OnInit, OnDestroy, OnChanges {

  @Input() user: User;
  @Input() userViewMode: string;
  @Output() selectedTabChange = new EventEmitter();

  public userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));
  public requestsExeOrCoor: Request[] = [];
  public requestsTeamMember: Request[] = [];
  public currentUser: string = this.localStorageService.getItemSync('user_id');
  public tableCfgExeOrCoor: IDataTableConfig;
  public tableCfgTeamMember: IDataTableConfig;
  public usersMap: IObjectMap<User> = {};
  public labels: any = {};
  public orgId = this.localStorageService.getItemSync('user_organization');
  public isLoading = false;
  public isComplete: boolean = false;
  public reqServices: { name: string, id: string, select: boolean, serviceCount: number }[] = [];
  private UserServices: string[] = [];
  private selectedServices: string[] = [];
  private sub = new NgSub();
  private services: Service[] = [];
  private cacheRequestsExeOrCoor: Request[];
  private cacheRequestsTeamMember: Request[];
  public currentRequestId: string;
  public creatorName: string;
  private allRequests: Request[] = [];

  constructor(
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-request-tab')).data;
    this.route.params.pipe(take(1)).toPromise().then(res => this.currentRequestId = res.requestId);
    this.getRequests(this.user, this.isComplete, this.userViewMode);
  }

  private async getRequests(user: User, isComplete: boolean, userViewMode?: string) {
    this.isLoading = true;
    this.requestService.getOpenRequestsForUser(user.id, isComplete, userViewMode)
      .subscribe(async requests => {
        if (requests.length) {
          this.cacheRequestsExeOrCoor = requests[0];
          this.cacheRequestsTeamMember = requests[1] || [];
          this.allRequests.push(...cloneDeep(this.cacheRequestsExeOrCoor));
          if (this.cacheRequestsTeamMember) this.allRequests.push(...cloneDeep(this.cacheRequestsTeamMember));
          await this.fetchUsers();
          this.getUserRole();
          this.getServices();
        } else {
          this.isLoading = false;
          this.requestsExeOrCoor = [];
          this.requestsTeamMember = []
          this.reqServices = [];
        }
      });
  }

  private async getServices() {
    const requestsServiesId: string[] = [];
    this.allRequests.forEach(req => requestsServiesId.push(req.management.serviceId))
    if (requestsServiesId.length) this.services = await this.userService.getUserServices(uniq(requestsServiesId));
    this.getChipData();
  }

  private async fetchUsers() {
    const executorIds: string[] = this.allRequests.map((request: Request) => request.management.executorId);
    const users = await this.userService.getUsersFromIds(uniq(executorIds)).pipe(take(1)).toPromise();
    this.usersMap = {};
    users.forEach((user: User) => {
      if (user) {
        this.usersMap[user.id] = user;
      }
    });
  }

  private getChipData() {
    this.reqServices = [];
    const reqs = this.allRequests;
    reqs.forEach((req) => {
      this.services.forEach((service) => {
        if (req.management.serviceId === service.id) {
          const serviceCount = reqs.filter(r => r.management.serviceId === service.id).length;
          this.reqServices.push({ name: service.name, id: service.id, select: false, serviceCount });
        }
      })
    })
    this.reqServices = uniqBy(this.reqServices, 'id');
    this.mapChipData();
  }

  private async updateTableDataExecOrCoor() {
    const config: IDataTableConfig = {
      data: this.getTableData(this.requestsExeOrCoor),
      displayProperties: ['title', 'executorFullName'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    }
    this.tableCfgExeOrCoor = config;
    this.isLoading = false;
  }

  private async updateTableDataTeamMember() {
    const config: IDataTableConfig = {
      data: this.getTableData(this.requestsTeamMember),
      displayProperties: ['title', 'executorFullName'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    }
    this.tableCfgTeamMember = config;
    this.isLoading = false;
  }

  private getTableData(requests: Request[]) {
    const data = [];
    requests.forEach((request: Request) => {
      return this.services.forEach(service => {
        const executor: User = this.usersMap[request.management.executorId];
        if (request.management.serviceId === service.id) {
          data.push({
            _rowClass: `status-${request.status}`,
            title: request.id === this.currentRequestId ? `${request.title} - ${this.labels.current_request}` : request.title,
            executorFullName: executor ? executor.fullname : '',
            service: service.name,
            originalRequest: request,
          })
        }
      })
    })
    return data;
  }

  // if user is a professional or has same connected service as the request the show details
  public handleRowSelect(res) {
    const request: Request = res.originalRequest;
    if (this.userRole.roleType === RoleTypes.professional ||
      this.userRole.roleType === RoleTypes.coordinator) {
      this.openRequestPage(request);
    } else {
      if (this.currentUser === request.management.executorId ||
        !isEmpty(request.management.team[this.currentUser]) ? this.currentUser === request.management.team[this.currentUser].userId : false) {
        this.openRequestPage(request);
      } else {
        this.userPromptsService.showToast(this.labels.you_cannot_view_details);
      }
    }
  }

  private openRequestPage(request: Request) {
    if (this.currentRequestId) {
      debounce(() => {
        this.selectedTabChange.emit(0);
      }, 500)
    }
    this.router.navigate([`/${this.orgId}/dashboard/requests/detail`, request.id]);
  }

  public toggleComplete(complete: boolean) {
    // this.getChipData()
    this.getRequests(this.user, complete, this.userViewMode);
  }

  public createRequest(): void {
    this.userPromptsService.showDialogue(NewRequestFormComponent, {
      customer: this.user
    }, null, false);
  }

  public filterByChip(reqService: { name: string, id: string, select: boolean, serviceCount: number }) {
    const index = this.reqServices.indexOf(reqService);
    const service = this.reqServices[index];
    if (index >= 0 && this.UserServices.includes(service.id)) {
      this.reqServices[index].select = !service.select;
      const selectedIndex = this.selectedServices.indexOf(service.id)
      if (selectedIndex >= 0) {
        this.selectedServices.splice(selectedIndex, 1);
      } else {
        this.selectedServices.push(service.id);
      }
      this.sortRequests();
    } else {
      this.userPromptsService.showToast(this.labels.no_access_service_details);
    }
  }

  public getUserRole() {
    this.sub.add(
      this.userService.getUserById(this.currentUser).subscribe(async user => {
        const keys = Object.keys(user.roles.workingAreas);
        this.UserServices = Object.keys(user.roles.services);
        const was = await Promise.all(keys.filter(key => {
          if (user.roles.workingAreas[key]?.professional?.active) {
            return true
          }
        }).map(k => this.userService.getUserWorkingAreas<WorkingArea>(k)))

        flatten(was).forEach(wa => {
          const sKeys = Object.keys(wa.services);
          this.UserServices = uniq(this.UserServices.concat(sKeys));
        });
      })
    )
  }

  private mapChipData() {
    this.selectedServices = [];
    this.reqServices.map(service => {
      if (this.UserServices.includes(service.id)) {
        service.select = true;
        if (!this.selectedServices.includes(service.id) && service.select === true) {
          this.selectedServices.push(service.id);
        }
      }
      return service;
    })
    this.sortRequests();
  }

  private sortRequests() {
    this.requestsExeOrCoor = this.cacheRequestsExeOrCoor.filter(req => {
      if (this.selectedServices.includes(req.management.serviceId)) {
        return req;
      }
    });
    this.requestsTeamMember = this.cacheRequestsTeamMember.filter(req => {
      if (this.selectedServices.includes(req.management.serviceId)) {
        return req;
      }
    });
    this.updateTableDataExecOrCoor();
    this.updateTableDataTeamMember();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userViewMode && this.isNewChange(changes.userViewMode)) {
      this.getRequests(this.user, this.isComplete, this.userViewMode);
    }
  }

  private isNewChange(change: any) {
    const prev = change.previousValue;
    const current = change.currentValue;
    return !isEqual(prev, current);
  }

  public toDate(d: any, skipYear?: boolean) {
    const date = new Date(d);

    if (isValid(date)) {
      return skipYear ? this.utilitiesService.getLocaleDateString(date) : this.utilitiesService.getLocaleDateWithYear(date)
    } else {
      return '';
    }
  }

  public async getRequestCreatorName(creatorId: string) {
    this.creatorName = creatorId ? (await this.userService.getUserById(creatorId).pipe(take(1)).toPromise())?.fullname : '';
  }
  
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
