var parse = require('../parse/index.js');

/**
 * @category Common Helpers
 * @summary Return the earliest of the given dates.
 *
 * @description
 * Return the earliest of the given dates.
 *
 * @param {...(Date|String|Number)} dates - the dates to compare
 * @returns {Date} the earliest of the dates
 *
 * @example
 * // Which of these dates is the earliest?
 * var result = min(
 *   new Date(1989, 6, 10),
 *   new Date(1987, 1, 11),
 *   new Date(1995, 6, 2),
 *   new Date(1990, 0, 1)
 * )
 * //=> Wed Feb 11 1987 00:00:00
 */
function min() {
  var dirtyDates = Array.prototype.slice.call(arguments);
  var dates = dirtyDates.map(function (dirtyDate) {
    return parse(dirtyDate);
  });
  var earliestTimestamp = Math.min.apply(null, dates);
  return new Date(earliestTimestamp);
}
module.exports = min;