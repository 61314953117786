<div class="main">
  <mat-progress-bar [style.opacity]="isLoading ? 1 : 0" mode="indeterminate"></mat-progress-bar>

  <div class="filters" fxLayout="row" fxLayoutGap="15px">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{labels.status}}</mat-label>
      <mat-select multiple [(ngModel)]="selectedStatuses" name="statuses"
        (valueChange)="onFilterChange()">
        <mat-option *ngFor="let status of defaultStatuses; " [value]="status">
          {{labels[RequestStatus[status]]}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{labels.service}}</mat-label>
      <mat-select multiple [(ngModel)]="selectedServices" name="statuses"
        (valueChange)="onFilterChange()">
        <mat-option *ngFor="let service of services; " [value]="service.id">
          {{ service.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style=" padding: 20px 20px 0;" *ngIf="tableData.length > 0">
    <data-table [configOptions]="getTableConfig()" (onSelectRow)="handleRowSelect($event)">
    </data-table>
  </div>

  <div *ngIf="tableData.length === 0" class="noting-found">
    {{labels.no_requests_found}}
  </div>
</div>