import { Component, OnInit } from '@angular/core';
import { RouterService, LocalStorageService } from 'app/core';
import { MenuItem, Organization, Role, Service, User } from 'app/shared/model';
import {
  RequestService,
  ISearchRequestsQuery,
  IToolbarSearchEvent,
  ToolbarSearchService,
  UserService,
  LabelService,
  OrgServiceService,
  OrganizationService,
  UtilitiesService
} from '@core/services';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from '@shared/BaseComponent';
import { RequestStatus } from 'app/shared/enum';
import { Request } from 'app/shared/model';
import { IObjectMap } from '@shared/interface';
import { IDataTableConfig } from '@shared/components';
import moment from 'moment';
import { uniq, compact, map, filter, orderBy } from 'lodash';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { take, takeUntil } from 'rxjs/operators';
import { NgSub } from 'ng-sub';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-volunteer-requests',
  templateUrl: './volunteer-requests.component.html',
  styleUrls: ['./volunteer-requests.component.scss']
})
export class VolunteerRequestsComponent extends BaseComponent implements OnInit {
  private requestsListSubscription: Subscription;
  public tableData: IObjectMap<any>[] = [];
  public requests: Request[];
  public filteredRequests: Request[];
  public screenBreakpoints: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  public isExtraSmall = false;
  public selectedStatuses: RequestStatus[] = [RequestStatus.assigned, RequestStatus.inProgress];
  public selectedServices: string[];
  public isLoading = false;

  public searchQuery: ISearchRequestsQuery = {
    search: '',
    serviceId: '',
    workareaId: '',
    coordinatorId: '',
    executorId: '',
    district: '',
    status: null
  };

  public services: Service[] = [];
  public coordinators: User[] = [];
  public executors: User[] = [];
  public usersMap: IObjectMap<User> = {};

  public defaultStatuses: RequestStatus[] = [
    RequestStatus.waitingList,
    RequestStatus.intake,
    RequestStatus.assigned,
    RequestStatus.inProgress,
    RequestStatus.closed
  ];
  public userRole: Role;
  public labels: any = {};
  public RequestStatus = RequestStatus;

  private org: Organization;
  private sub = new NgSub();
  private menuItem: MenuItem = Object.assign({}, new MenuItem(), {
    title: 'service_requests',
    searchEnabled: true,
    searchPlaceholder: this.labels.search_service_request
  });

  constructor(
    private localStorageService: LocalStorageService,
    private routerService: RouterService,
    private router: Router,
    private route: ActivatedRoute,
    private requestsService: RequestService,
    private userService: UserService,
    private toolbarSearchService: ToolbarSearchService,
    private labelService: LabelService,
    private orgServiceService: OrgServiceService,
    private orgService: OrganizationService,
    public breakpointObserver: BreakpointObserver,
    private utilitiesService: UtilitiesService,
  ) {
    super();
    this.subscriptions.push(this.screenBreakpoints.subscribe((result) => {
      this.isExtraSmall = result.matches;
    }));
  }

  async ngOnInit() {
    this.routerService.setMainMenuItem(this.menuItem);
    this.initToolbarSearch();

    this.localStorageService.getItem('user_role').subscribe(() => {
      this.initRole();
    });

    this.orgService.getCurrentOrganization().pipe(takeUntil(this.sub)).subscribe({
      next: (org) => {
        this.org = org;
        this.updateTableData();
      },
      error: e => console.log(e),
    });

    this.labels = (await this.labelService.getLabels('app-volunteer-requests')).data;
  }

  public initRole() {
    this.selectedServices = null;
    this.searchQuery.executorId = this.localStorageService.getItemSync('user_id');
    this.fetchRequests();
  }

  public onFilterChange() {
    setTimeout(() => {
      this.filterRequests();
      this.updateTableData();
    });
  }

  private async fetchRequests() {
    this.isLoading = true;
    await this.fetchServicesForExecutor();

    this.requestsService.getAllRequestsForExecutor(this.searchQuery.executorId, this.services)
      .subscribe((res) => {
        this.requests = orderBy(res, ['status'], ['asc']);
        this.filterRequests();  // display fields w/o users info for now
        this.updateTableData();
        this.fetchUsers();      // and update fields ASAP
        this.isLoading = false;
      });

    this.subscriptions.push(this.requestsListSubscription);
  }

  private fetchUsers() {
    const customerIds: string[] = this.requests.map((request: Request) => request.management.customerId);
    const executorIds: string[] = this.requests.map((request: Request) => request.management.executorId);
    const ids: string[] = compact(uniq(customerIds.concat(executorIds))); // get unique Ids and remove empty values

    this.userService.getUsersFromIds(ids).pipe(take(1))
      .subscribe((users: User[]) => {
        this.usersMap = {};
        users.forEach((user: User) => {
          if (user) {
            this.usersMap[user.id] = user;
          }
        });
        this.updateTableData();
      });
  }

  private async fetchServicesForExecutor() {
    const services = await this.orgServiceService.getServicesForExecutor(this.searchQuery.executorId);
    this.services = services;

    const serviceIds = services.map(s => s.id);
    if (!this.selectedServices) {
      this.selectedServices = serviceIds;
    }
  }

  private async initToolbarSearch() {
    const newSub: Subscription = this.toolbarSearchService.getEmitter()
      .subscribe(async (event: IToolbarSearchEvent) => {
        if (event.event === 'search') {
          this.filterRequests(event.val);
          this.updateTableData();
        }
      });
    this.subscriptions.push(newSub);
  }

  updateTableData() {
    this.tableData = map(this.filteredRequests, (request: Request) => {
      const daysIn: any = (request.log && request.log.createdAt)
        ? moment().diff(moment(request.log.createdAt), 'days') : '';
      const customer: User = this.usersMap[request.management.customerId];

      return {
        _statusValue: { val: request.status, org: this.org, },
        // _rowClass: `status-${request.status}`,
        customer: customer ? customer.fullname : '',
        title: request.title,
        date: (request.log && request.log.createdAt) ? moment(request.log.createdAt).format('L LT') : '',
        daysIn: daysIn,
        originalRequest: request
      };
    });
  }

  handleRowSelect(res) {
    const request = res.originalRequest;
    const scroller = document.getElementById('scrollElemBody');
    const scroll = Math.round(scroller?.scrollTop || 0);

    this.router.navigate([`/${this.org.id}/dashboard/requests/detail`, request.id], { relativeTo: this.route }).then(() => {
      const sub = this.route.url.subscribe(() => {
        const url = this.router.url;

        if (!url.endsWith(`/dashboard/requests/detail/${request.id}`)) {
          if (url.endsWith('dashboard/requests/list') && scroll > 0) {
            this.utilitiesService.delay(500).subscribe(() => {
              scroller.scrollTop = scroll;
            });
          }

          sub.unsubscribe();
        }
      });
    });
  }

  getDisplayedColumns(): string[] {
    if (this.isExtraSmall) {
      return ['status-row', 'customer'];
    } else {
      return ['status-row', 'customer', 'title', 'daysIn'];
    }
  }

  filterRequests(word?: string) {
    this.filteredRequests = filter(this.requests, (request: Request) => {
      if (this.selectedStatuses.indexOf(request.status) === -1) {
        return false;
      }

      if (this.selectedServices && this.selectedServices.indexOf(request.management.serviceId) === -1) {
        return false;
      }

      if (word) {
        let requestString: string = JSON.stringify(request);

        if (request.management.customerId && this.usersMap[request.management.customerId]) {
          requestString += JSON.stringify(this.usersMap[request.management.customerId]);
        }
        requestString = requestString.toLowerCase();

        if (requestString.indexOf(word.toLowerCase()) === -1) {
          return false;
        }
      }

      return true;
    });
  }

  getTableConfig(): IDataTableConfig {
    return {
      data: this.tableData,
      displayProperties: this.getDisplayedColumns(),
      headers: {
        customer: this.labels.customer,
        title: this.labels.title,
        daysIn: this.labels.days_in,
      },
      rowOptions: [],
      allowSelection: false,
      highlightStatuses: true,
      displayHeaders: true
    };
  }
}
