<div fxLayout="column" fxLayoutGap="20px">
    <mat-progress-bar [style.opacity]="isLoading ? 1 : 0" mode="indeterminate"></mat-progress-bar>
    <div fxLayout="row" fxLayout.xs="column">
        <div *ngIf="userViewMode === 'customer'" style="margin-right: auto;">
            <button *ngIf="userRole.roleType !== 1 && !currentRequestId" mat-stroked-button color="primary"
                (click)="createRequest()">
                {{labels.add_request}}
            </button>
        </div>

        <div>
            <mat-chip-listbox multiple="true">
                <mat-chip-option *ngFor="let service of reqServices" color="primary" [selectable]="true"
                    [selected]="service.select" [value]="service" (click)="filterByChip(service)">{{service.name}}
                    ({{service.serviceCount}})</mat-chip-option>
            </mat-chip-listbox>
        </div>

    </div>
    <div>
        <div *ngIf="requestsExeOrCoor.length">
            <ng-container *ngTemplateOutlet="expansionPanel; context: {$implicit: tableCfgExeOrCoor}"></ng-container>
        </div>

        <div class="mt-3" *ngIf="requestsTeamMember.length">
            <div style="margin-bottom: 5px;">{{labels.team_member}}</div>
            <div>
                <ng-container
                    *ngTemplateOutlet="expansionPanel; context: {$implicit: tableCfgTeamMember}"></ng-container>
            </div>
        </div>

        <!-- <data-table *ngIf="requests.length" [configOptions]="tableCfg" (onSelectRow)="handleRowSelect($event)">
        </data-table> -->
    </div>

    <div *ngIf="!requestsExeOrCoor.length && !isLoading" class="text-center">{{labels.no_requests}}</div>
    <div class="p-2" fxFlexAlign="end">
        <mat-slide-toggle color="primary" [disabled]="!requestsExeOrCoor" [(ngModel)]="isComplete"
            (ngModelChange)="toggleComplete($event)">
            {{labels.show_completed}}
        </mat-slide-toggle>
    </div>

</div>

<ng-template #expansionPanel let-viewData>
    <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let row of viewData.data"
            [disabled]="row.originalRequest.id === currentRequestId"
            (opened)="getRequestCreatorName(row.originalRequest.log.createdBy)">

            <mat-expansion-panel-header [ngClass]="[row._rowClass ? row._rowClass : '']">
                <mat-panel-title style="width: 30%;">
                    {{row.title}}
                </mat-panel-title>
                <mat-panel-description style="width: 30%;">
                    {{row.service}}
                </mat-panel-description>
                <mat-panel-description *ngIf="userViewMode === 'customer'" style="width: 30%;">
                    {{row.executorFullName}}
                </mat-panel-description>
                <mat-panel-description *ngIf="userViewMode === 'employee'" style="width: 30%;">
                    {{row.originalRequest.management.customer.firstname}}
                    {{row.originalRequest.management.customer.lastname}}
                </mat-panel-description>

            </mat-expansion-panel-header>
            <div fxLayout="column" class="settings_content" style="margin-left: 15px;">
                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px;">
                    <div fxFlex="stretch" fxLayout="column">
                        <div class="text-primary">{{labels.created_at}}</div>
                        <div>{{toDate(row.originalRequest.log.createdAt)}}</div>
                    </div>
                    <div fxFlex="stretch" fxLayout="column">
                        <div class="text-primary">{{labels.start_date}}</div>
                        <div>{{row.originalRequest?.startDate}}</div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="stretch" fxLayout="column">
                        <div class="text-primary">{{labels.created_by}}</div>
                        <div>{{creatorName}}</div>
                    </div>
                    <div fxFlex="stretch" fxLayout="column">
                        <div class="text-primary">{{labels.end_date}}</div>
                        <div>{{row.originalRequest?.endDate}}</div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
                    <div fxFlex="stretch" fxLayout="column"></div>
                    <div fxLayout="column">
                        <button class="text-primary" style="width: max-content;" mat-button
                            (click)="handleRowSelect(row); selectedTabChange.emit($event);">{{labels.go_to_request}}</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>