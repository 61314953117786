import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilitiesService } from '@core/services';
import { LabelService, WeekDayItem } from '@core/services/labels.service';
import { IObjectMap } from '@models/interface';
import { User, UserAvailabilityTypes, UserDailyAvailability } from '@models/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { cloneDeep, isNaN } from 'lodash';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-availability-selector',
  templateUrl: './availability-selector.component.html',
  styleUrls: ['./availability-selector.component.scss']
})
export class AvailabilitySelectorComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public selected: User;
  public users: User[] = [];
  public weekdays: WeekDayItem[] = [];
  public availabilitySessions: string[] = [];
  public selectedWeekday: string;
  public searchText = '';
  public textShortener: IObjectMap<boolean> = {};
  public field: string;
  private sub = new NgSub();
  private periodSessions = ['morning', 'afternoon', 'evening', 'night'];
  public executorsStatus: IObjectMap<{ status: string; count: number }[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { users: User[]; selected: User; customer?: User; field: string },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<AvailabilitySelectorComponent>,
    private labelService: LabelService,
    private utilitiesService: UtilitiesService
  ) {
    super(dialogRef, breakpointObserver);
  }

  ngOnInit() {
    this.selected = this.data.selected;

    this.labelService.getWeekdayList().then(w => {
      // changes order so that monday is first and sunday is last
      this.weekdays = w.slice(1).concat([w[0]]);

      // const weekday = getDay(new Date());
      this.selectWeekday();
    });

    this.labelService.getLabels('app-availability-selector').then(r => this.labels = r.data);
    this.field = this.data.field;
  }

  public hoverEffect(id: string, action: boolean) {
    if (id === this.selected?.id) {
      return;
    }

    const elem = document.getElementById(id);

    if (action) {
      elem.classList.add('border-primary');
    } else {
      elem.classList.remove('border-primary');
    }
  }

  public selectWeekday(day?: WeekDayItem): void {
    this.selectedWeekday = day?.prop;
    this.filterUsers();

    if (this.selectedWeekday) {
      this.availabilitySessions = cloneDeep(this.periodSessions);
    } else {
      this.availabilitySessions = this.weekdays.map(w => w.prop);
    }
  }

  public userSubtitle(user: User): string {
    let s = user.area?.neighbourhood?.text || '';

    if (this.data.customer?.address?.geo && user.address?.geo) {
      const dst = this.utilitiesService.distance(
        user.address.geo['longitude'],
        user.address.geo['latitude'],
        this.data.customer.address.geo['longitude'],
        this.data.customer.address.geo['latitude'],
        'K'
      );

      if (!isNaN(dst)) {
        if (s.length > 0) {
          s += ' - ';
        }

        s += dst + 'km';
      }
    }

    return s;
  }

  public commentText(user: User): string {

    let text: string = user.availability?.availability === UserAvailabilityTypes.notAvailable ? new Date(user.availability?.notAvailableUntil).toLocaleDateString() || '' : '';

    if (text.length > 0) {
      text += '\n';
    }

    if (this.selectedWeekday) {
      if (user.availability?.days[this.selectedWeekday]?.remark.length > 0) {
        text += user.availability?.days[this.selectedWeekday]?.remark;
        text += '\n';
      }
    } else {
      if (user.availability?.comment.length > 0) {
        text += user.availability?.comment;
        text += '\n';
      }
    }

    if (user.employeeDetails?.offers) {
      if (user.employeeDetails?.offers.length > 0) {
        text += user.employeeDetails?.offers || '';
      }
    }
    return text;
  }

  public onSearch(text: string): void {
    this.searchText = text;
    this.filterUsers();
  }

  public isActive(item: IObjectMap<UserDailyAvailability>, session: string): boolean {
    if (!item) {
      return false;
    }

    if (!this.selectedWeekday) {
      return item[session].available;
    }

    const s = item[this.selectedWeekday]?.[session];
    return s?.available;
  }

  private filterUsers(): void {
    this.users = (this.data.users || []).filter(u => {
      if (!u.availability && this.selectedWeekday) {
        return false;
      }

      const item = this.selectedWeekday ? u.availability.days[this.selectedWeekday] as UserDailyAvailability : null;
      if (this.selectedWeekday && !item?.available) {
        return false;
      }

      if (this.searchText) {
        const value = `${u.fullname} ${u.availability?.comment} ${u.employeeDetails?.offers} ${u.area?.neighbourhood?.text} ${u.area?.district?.text}`.toLowerCase();

        return value.indexOf(this.searchText.toLowerCase()) >= 0;
      } else {
        return true;
      }
    });
    this.mapExecutors();
  }

  private mapExecutors() {
    const obj = {};
    this.users.forEach(user => {
      obj[user.id] = [];
      if (user?.executorDetails?.requestsCounts) {
        for (const key in user.executorDetails.requestsCounts) {
          obj[user.id].push({
            status: key,
            count: user.executorDetails.requestsCounts[key]
          })
        }
      }
    });
    this.executorsStatus = obj;
  }

  public userSelected(user: User): void {
    this.selected = user;
    this.dialogRef.close(user);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
