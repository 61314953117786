<div class="main">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '18%', 'min-height': '150px',  'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div style="position: relative;">
        <div style="position: absolute; top: -20px; width: 100%;"><mat-progress-bar *ngIf="isLoading"
            mode="indeterminate"></mat-progress-bar></div>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 10px;">
          <mat-label>{{labels.search_term}}</mat-label>
          <input matInput [(ngModel)]="requestFilter.textFilter" (ngModelChange)="checkTextFilterValid()"
            (keydown.enter)="handleFilterSubmit()">
        </mat-form-field>

        <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
          <button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !requestFilter.textFilter"
            (click)="clearFilters()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 10px;">
          <mat-label>{{labels.status}}</mat-label>
          <mat-select (selectionChange)="handleFilterSubmit()" [(ngModel)]="requestFilter.statusFilter" name="status">
            <mat-option class="status-item status-default" [value]="null">
              <div fxLayout="row" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
                <ng-container *ngTemplateOutlet="statusNumberTmpl;context: {num: 0}"></ng-container>
                <div fxFlex="stretch">{{labels.show_all}}</div>
              </div>
            </mat-option>
            <mat-option [ngClass]="['status-item status-'+status]" *ngFor="let status of getStatuses()"
              [value]="status">
              <div fxLayout="row" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
                <ng-container *ngTemplateOutlet="statusNumberTmpl;context: {num: status}"></ng-container>
                <div fxFlex="stretch">{{labels[RequestStatus[status]]}}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '30%', 'min-height': '150px',  'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row">

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.working_area}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.workingAreaFilter"
            [disabled]="userRole.roleType === 2 || userRole.roleType === 3 ">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let v of workingAreas" [value]="v?.id">
              {{v?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.serviceFilter" [disabled]="userRole.roleType === 2"
            (ngModelChange)="getServices()" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let s of services | sort:'name'" [value]="s.id">
              {{s.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.executor}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.executorFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let exec of executors" [value]="exec.id">
              {{exec.fullname}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.coordinator}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.coordinatorFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let coord of coordinators" [value]="coord.id">
              {{coord.fullname}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="advanceFilterEnabled">
        <div fxLayout="row">
          <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
            <mat-label>{{labels.referred_by}}</mat-label>
            <mat-select [(ngModel)]="requestFilter.referredByFilter"
              [disabled]="!selectedWorkingArea?.settings?.referredByValues" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="null">
                {{labels.show_all}}
              </mat-option>
              <mat-option *ngFor="let v of selectedWorkingArea.settings.referredByValues" [value]="v">
                {{v}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
            <mat-label>{{labels.result}}</mat-label>
            <mat-select [(ngModel)]="requestFilter.resultFilter" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="null">
                {{labels.show_all}}
              </mat-option>
              <mat-option *ngFor="let rr of requestResult" [value]="rr.id">
                {{rr.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      @if (advanceFilterEnabled) {
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.referred_by_institution}}</mat-label>
          <input matInput [value]="requestFilter.referredByInstitution?.name || ''" type="text" readonly>

          <mat-icon style="max-height: fit-content;" matSuffix class="cursor_pointer" color="primary"
            (click)="openInstitutionAndContactPersonSelector('referredByInstitution')">store</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.referred_by_contact_person}}</mat-label>
          <input matInput [value]="requestFilter.referredByContactPerson?.firstname" type="text" readonly>

          <mat-icon style="max-height: fit-content;" matSuffix color="primary" class="cursor_pointer"
            [ngStyle]="{'color': requestFilter.referredByInstitution ? '' : 'gray'}"
            (click)="openInstitutionAndContactPersonSelector('referredByContactPerson')">
            account_box_multiple
          </mat-icon>
        </mat-form-field>
      </div>
      }

    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '30%', 'min-height': '150px',  'max-width': '350px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row">

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.select_year}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.activeInYearFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let y of yearsList" [value]="y">
              {{y}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.select_month}}</mat-label>
          <mat-select multiple [(ngModel)]="requestFilter.activeInMonthFilter"
            [disabled]="!requestFilter.activeInYearFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let m of monthNames" [value]="m.id">
              {{m.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.kind}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.kindFilter" [disabled]="!requestFilter.workingAreaFilter"
            (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let kind of kindsList" [value]="kind">
              {{kind}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.product}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.productFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let prod of productsList" [value]="prod.code">
              {{prod.name}} ({{prod.code}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="advanceFilterEnabled" fxLayout="column">
        <div fxLayout="row">

          <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 10px;">
            <mat-label>{{labels.municipality}}</mat-label>
            <input [disabled]="municipalities.length === 0" type="text" matInput
              [(ngModel)]="requestFilter.municipalityFilter" [matAutocomplete]="auto"
              (ngModelChange)="onMunicipality($event)">
            <mat-icon matSuffix style="cursor: pointer" *ngIf="canClearText" (click)="clearSelection()">close
            </mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMunicipalityName">
              <mat-option *ngFor="let m of filterMunicipalities()" [value]="m">
                {{m.text}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
            <mat-label>{{labels.district}}</mat-label>
            <mat-select [disabled]="!requestFilter.municipalityFilter" [(ngModel)]="requestFilter.districtFilter"
              name="district" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="''">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let district of districts;" [value]="district.code">{{district.text}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
            <mat-label>{{labels.neighborhood}}</mat-label>
            <mat-select [disabled]="!requestFilter.districtFilter" [(ngModel)]="requestFilter.neighborhoodFilter"
              name="neighborhood" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="''">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let neighborhood of getNeighborhoodList()" [value]="neighborhood.code">
                {{neighborhood.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '18%', 'min-height': '150px',  'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px"
        style="margin-bottom: 40px; padding-left: 10px; padding-right: 10px;">
        <button mat-raised-button color="primary" (click)="handleFilterSubmit()"
          [disabled]="isSearchButtonDisabled">{{labels.search}}</button>

        <button mat-stroked-button color="primary" aria-label="Advance" (click)="advance()">
          {{!advanceFilterEnabled ? labels.more : labels.less}}
        </button>

      </div>
      <!-- <div fxFlex="stretch"></div> -->
      <div fxLayout="row">
        <!-- todo : implement serch in -->
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 10px;">
          <mat-label>{{labels.search_in}}</mat-label>
          <mat-select disabled [(ngModel)]="requestFilter.searchInFilter">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option value="open">
              {{labels.open_requests}}
            </mat-option>
            <mat-option value="closed">
              {{labels.closed_requests}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.overDueFilter" (change)="handleFilterSubmit()">
          {{labels.over_due}}
        </mat-checkbox>
      </div>
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.publishedFilter" (change)="handleFilterSubmit()">
          {{labels.published}}
        </mat-checkbox>
      </div>
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.signalFilter" (change)="handleFilterSubmit()">
          {{labels.signal}}
        </mat-checkbox>
      </div>
    </mat-card>
    <!-- <button mat-stroked-button (click)="updateActivities()">
      update activity
    </button> -->
  </div>

  <div style="padding-top: 20px;" *ngIf="dataTableConfig?.data.length > 0" fxl>
    <data-table [configOptions]="dataTableConfig" (onSelectRow)="handleRowSelect($event)"
      (onSelectRowAction)="handleRowAction($event)">
    </data-table>

    <!-- <div *ngIf="isLoading" style="padding: 20px 0;">
      <div fxLayoutAlign="center center">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </div> -->
  </div>
  <div style="margin-top: -10px;" class="footer">
    <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
      [pageSize]="paginationOptions.requestsPerPage" [pageIndex]="paginationOptions.pageIndex"
      [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
    </mat-paginator>
  </div>

  <div *ngIf="tableData.length === 0 && !isLoading" class="py-4">
    <app-empty-list [title]="isAllEmpty ? labels.empty_requests_title : labels.empty_requests_title_alt"
      [description]="isAllEmpty ? labels.empty_requests_desc : labels.empty_requests_desc_alt">
    </app-empty-list>
  </div>

  <div class="actions" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div>
      <button mat-raised-button aria-label="Export Requests" (click)="exportRequests()">
        {{labels.export_requests}}
      </button>
    </div>

    <button mat-fab class="add-user-button" (click)="openAddUserDialog()" color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<ng-template #statusNumberTmpl let-num="num">
  <div [class]="'status-impl-parent status-bg-'+num" fxLayout="row" fxLayoutAlign="center center">
    <div [class]="'status-tmpl status-color-'+num" fxLayout="row" fxLayoutAlign="center center">
      {{num}}
    </div>
  </div>
</ng-template>

<ng-template #customChecklistBtnBody let-row="row">
  <span *ngIf="canShowChecklistIcon(row.originalRequest?.management.serviceId)" [class]="checklistBtnColor(row)"
    (click)="openChecklistDialog(row.originalRequest)">
    <mat-icon>checklist</mat-icon>
  </span>
</ng-template>