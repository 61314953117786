import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
// import { Activity } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class ReportingRequestService {

  private orgId: string;
  private colRef: string;

  constructor(
    private afsDB: FirestoreService,
    private localStorageService: LocalStorageService,
  ) {
    this.localStorageService.getItem('user_organization').subscribe((res) => {
      this.orgId = res;
      this.colRef = `/organizations/${this.orgId}/reportingRequest`;
    });
  }


  public deleteReportingRequest(reportingRequestId: string) {
    this.afsDB.remove(`${this.colRef}/${reportingRequestId}`);
  }

  // /**
  //  * For migration - like purpose
  //  */
  // public getActivitiesForUpdate() {
  //   return this.afsDB.colGroupWithIds$<Activity>('activities', ref => ref.where('kind', '==', 11));
  // }

  // /**
  //  * For migration - like purpose
  //  */
  // public updatedActivities(docItem: Activity) {
  //   return this.afsDB.update(`organizations/${docItem.orgId}/users/${docItem.userId}/activities/${docItem.id}`, docItem as any);
  // }
}
