import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitiesService } from '@core/services';
import { RoleTypes } from '@shared/enum';

@Injectable({
    providedIn: 'root',
})
export class InstitutionsListGuard  {
    public constructor(
        private utilitiesService: UtilitiesService,
        private router: Router
    ) { }

    public canActivate(): boolean {
        const match = this.utilitiesService.rolesMatch(
            RoleTypes.professional,
            RoleTypes.coordinator,
            RoleTypes.excecutor
        );

        if (!match) {
            this.router.navigateByUrl('/');
        }

        return match;
    }
}
