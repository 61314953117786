<div class="main">

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '20%', 'min-height': '150px', 'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div style="position: relative;">
        <div style="position: absolute; top: -20px; width: 100%;"><mat-progress-bar *ngIf="isLoading"
            mode="indeterminate"></mat-progress-bar></div>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.search_term}}</mat-label>
          <input matInput [(ngModel)]="userFilter.textFilter" (ngModelChange)="checkTextFilterValid()"
            (keydown.enter)="handleFilterSubmit()">
        </mat-form-field>

        <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
          <button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
            (click)="clearFilters()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <div fxLayout="row" style="padding-left: 10px; padding-right: 10px;">
        <div fxFlex="1 1 100%" fxLayoutAlign="space-between center">
          <div fxFlex="1 1 57%">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{labels.postalcode}}</mat-label>
              <input matInput [(ngModel)]="userFilter.postalCodeFilter" pattern="[0-9]{4}[A-Za-z]{2}" mask="0000SS"
                (ngModelChange)="checkPostalCodeValid()" (keydown.enter)="handleFilterSubmit()">
            </mat-form-field>
          </div>
          <div fxFlex="1 1 37%">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Nr</mat-label>
              <input matInput #houseNumberInput [(ngModel)]="userFilter.houseNumberFilter" mask="0*"
                (keydown.enter)="handleFilterSubmit()" (keydown.Tab)="handleHouseNumberTabOut($event)">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '30%', 'min-height': '150px', 'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.role}}</mat-label>
          <mat-select [(ngModel)]="userFilter.rolesFilter" (ngModelChange)="handleRoleChange()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option value="exec">
              {{labels.executor}}
            </mat-option>
            <mat-option value="coord">
              {{labels.coordinator}}
            </mat-option>
            <mat-option value="prof">
              {{labels.professional}}
            </mat-option>
            <mat-option value="cus">
              {{labels.customer}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.status}}</mat-label>
          <mat-select [disabled]="(userFilter.rolesFilter != 'exec' && userFilter.rolesFilter != 'cus')|| !userFilter.serviceFilter"
            [(ngModel)]="userFilter.statusFilter" (ngModelChange)="handleRoleChange()">
            <mat-option [value]="false"> <!-- the value uses disabled property to query. So its disabled: false-->
              {{labels.active}}
            </mat-option>
            <mat-option [value]="true"> <!-- the value uses disabled property to query. So its disabled: true-->
              {{labels.inactive}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div fxLayout="row">

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
          <mat-label>{{labels.working_area}}</mat-label>
          <mat-select [(ngModel)]="userFilter.workingAreaFilter" (ngModelChange)="getServices()"
            (selectionChange)="handleFilterSubmit()"
            [disabled]="(userRole.roleType === 2 || userRole.roleType === 3 || !userFilter.rolesFilter || (userFilter.rolesFilter === '-1'))">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let v of workingAreas" [value]="v.id">
              {{v.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select [(ngModel)]="userFilter.serviceFilter"
            [disabled]="(userRole.roleType === 2 || userFilter.rolesFilter === 'prof' || !userFilter.rolesFilter || (userFilter.rolesFilter === '-1'))"
            (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let s of services | sort:'name'" [value]="s.id">
              {{s.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="advanceFilterEnabled" fxLayout="column">

        <div fxLayout="row">
          <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 10px;">
            <mat-label>{{labels.municipality}}</mat-label>
            <input [disabled]="municipalities.length === 0" type="text" matInput
              [(ngModel)]="userFilter.municipalityFilter" [matAutocomplete]="auto"
              (ngModelChange)="onMunicipality($event)">
            <mat-icon matSuffix style="cursor: pointer" *ngIf="canClearText" (click)="clearSelection()">close
            </mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMunicipalityName">
              <mat-option *ngFor="let m of filterMunicipalities()" [value]="m">
                {{m.text}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field appearance="outline" class="full-width" style="padding-left: 10px; padding-right: 5px;">
            <mat-label>{{labels.district}}</mat-label>
            <mat-select [disabled]="!userFilter.municipalityFilter" [(ngModel)]="userFilter.districtFilter"
              name="district" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="''">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let district of districts;" [value]="district.code">{{district.text}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width" style="padding-left: 5px; padding-right: 10px;">
            <mat-label>{{labels.neighborhood}}</mat-label>
            <mat-select [disabled]="!userFilter.districtFilter" [(ngModel)]="userFilter.neighborhoodFilter"
              name="neighborhood" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="''">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let neighborhood of getNeighborhoodList()" [value]="neighborhood.code">
                {{neighborhood.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '20%', 'min-height': '150px', 'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" *ngIf="org"
          style="padding-left: 10px; padding-right: 10px;">
          <mat-label>{{labels.membership}}</mat-label>
          <mat-select [(ngModel)]="userFilter.membership" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let membership of org.settings?.membershipSettings?.membershipKinds"
              [value]="membership">
              {{membership}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" class="full-width" *ngIf="mailinglists"
          style="padding-left: 10px; padding-right: 10px;">
          <mat-label>{{labels.mailinglists}}</mat-label>
          <mat-select [(ngModel)]="userFilter.mailingList" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let mailing of mailinglists" [value]="mailing.id">
              {{mailing.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '20%', 'min-height': '150px',  'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px"
        style="margin-bottom: 40px;padding-left: 10px; padding-right: 10px;">
        <button mat-raised-button color="primary" (click)="handleFilterSubmit()"
          [disabled]="isSearchButtonDisabled">{{labels.search}}</button>

        <button mat-stroked-button color="primary" aria-label="Advance" (click)="advance()">
          {{!advanceFilterEnabled ? labels.more : labels.less}}
        </button>
      </div>
      <!-- <div fxFlex="stretch"></div> -->
      <div fxLayout="row" style="padding-left: 10px; padding-right: 10px;">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.order_by}}</mat-label>
          <mat-select [(ngModel)]="userFilter.orderByFilter">
            <mat-option value="last-name">
              {{labels.last_name}}
            </mat-option>
            <mat-option value="first-name">
              {{labels.first_name}}
            </mat-option>
            <mat-option value="street">
              {{labels.street}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
  </div>

  <div fxLayout="row" fxLayoutGap="15px">
    <div fxFlex="stretch">
      <h2 *ngIf="this.userFilter.defaultLoadLastSeen">{{labels.recently_seen}}</h2>
      <h2 *ngIf="!this.userFilter.defaultLoadLastSeen">{{labels.search_result}}</h2>
    </div>
    <button button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
      [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
      (click)="openEmailDialog()"><mat-icon>mail</mat-icon></button>

    <button button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !userFilter.textFilter"
      [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
      (click)="downloadAddress()"><mat-icon>download</mat-icon></button>

    <button button mat-mini-fab [disabled]="!userFilter.rolesFilter"
      [color]="anyNonSearchTextFiltersSet() || userFilter.textFilter ? 'primary' : ''"
      (click)="openBirthdayExportUsersDialog()"><mat-icon>cake</mat-icon></button>

    <div *ngIf="userRole.roleType === roleTypes.orgAdmin">
      <button mat-raised-button [matMenuTriggerFor]="controls" class="import-users-button">
        {{labels.options}} <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #controls="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="importcontrols">
          {{labels.import}}
        </button>

        <button mat-menu-item (click)="openExportUsersDialog()">
          {{labels.export}}
        </button>
      </mat-menu>

      <mat-menu #importcontrols="matMenu">
        <button mat-menu-item (click)="openImportUsersDialog(ImportUserTypes.users)">
          {{labels.users}}
        </button>
        <button mat-menu-item (click)="openImportUsersDialog(ImportUserTypes.coaches)">
          {{labels.coaches}}
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="dataTableConfig?.data.length > 0">
    <data-table [configOptions]="dataTableConfig" (onSelectRow)="handleRowSelect($event)"
      (onSelectRowAction)="handleRowAction($event)" (rowMenuOptionBtnClicked)="rowMenuOptionBtnCLicked($event)">
    </data-table>

    <div style="margin-top: -10px;" class="footer">
      <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
        [pageSize]="paginationOptions.usersPerPage" [pageIndex]="paginationOptions.pageIndex"
        [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <div *ngIf="dataTableConfig && dataTableConfig.data.length === 0 && !userFilter.defaultLoadLastSeen">
    <app-empty-list [actionText]="labels.create_new_user" (onAction)="createNewUser()"
      [actionDisabled]="isLoading || (letters.length > 0 && !userAddress.houseNumberAddition)"
      [title]="isAllEmpty ? labels.empty_users_title : labels.empty_users_title_alt"
      [description]="(isAllEmpty ? labels.empty_users_desc : labels.empty_users_desc_alt) + '\n'+ addressString">
      <div belowDescription>
        <div *ngIf="letters.length">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.letter}}</mat-label>
            <mat-select #letter [(ngModel)]="houseLetter" [disabled]="letters?.length === 0">
              <mat-option *ngFor="let letter of letters" [value]="letter" (onSelectionChange)="selectLetter(letter)">
                {{letter}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </app-empty-list>
  </div>

  <div *ngIf="dataTableConfig && dataTableConfig.data.length === 0 && !isLoading && userFilter.defaultLoadLastSeen">
    <app-empty-list [actionText]="labels.create_new_user" (onAction)="createNewUser()"
      [title]="isAllEmpty ? labels.empty_last_seen_users_title : labels.empty_last_seen_users_title_alt"
      [description]="isAllEmpty ? labels.empty_last_seen_users_desc : labels.empty_last_seen_users_users_desc_alt">
    </app-empty-list>
  </div>

  <div>
    <button mat-fab class="add-user-button"
      [disabled]="isLoading || (letters.length > 0 && !userAddress.houseNumberAddition)" (click)="createNewUser()"
      color="primary">
      <mat-icon>person_add</mat-icon>
    </button>
  </div>
</div>