import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { Activity, Municipality, Request, RequestActiveTimeline, RequestReaction, Role, Service } from 'app/shared/model';
import { RequestStatus } from '@shared/enum';
import { SearchService } from './search.service';
import { ApiResponse, ApiService } from './api.service';
import { UserService } from './user.service';
import { filter, map, mergeAll, take } from 'rxjs/operators';
import { flatten, padStart, uniq, uniqBy } from 'lodash';
import { StoreService } from './store/store.service';
import { addMonths, format, getYear, isBefore, isEqual, isValid, startOfMonth } from 'date-fns';
import { RequestFilter } from 'app/requests-module/components';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
import { UnitTypes } from '@models/model/product';
import { TimestampDate } from 'timestamp-date';
import { IObjectMap } from '@models/interface';

export interface ISearchRequestsQuery {
  search?: string;
  serviceId?: string;
  workareaId?: string;
  coordinatorId?: string;
  executorId?: string;
  customerId?: string;
  district?: string;
  status?: RequestStatus;
  limit?: number;
  neighborhood?: string;
  forExport?: boolean;
  noSort?: boolean;
  municipality?: Municipality;
  activeinYear?: Number;
  isClosed?: boolean;
  lastRef?: any,
  firstRef?: any,
  referredBy?: string;
  result?: string
}

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private orgId: string;
  private timeStamp = new TimestampDate();

  constructor(
    private afsDB: FirestoreService,
    private searchService: SearchService,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private userService: UserService,
    private storeService: StoreService,
  ) {
    this.localStorageService.getItem('user_organization').subscribe((res) => {
      this.orgId = res;
    });
  }

  public getRequestReactions(requestId: string): Observable<RequestReaction[]> {
    const colRef = `/organizations/${this.orgId}/requests/${requestId}/requestReactions`;
    return this.afsDB.colWithIds$(colRef);
  }

  public updateRequestReaction(requestId: string, rxn: RequestReaction): Promise<void> {
    const colRef = `/organizations/${this.orgId}/requests/${requestId}/requestReactions/${rxn.id}`;
    return this.afsDB.update(colRef, rxn as any);
  }

  public deleteRequestReaction(requestId: string, rxnId: string): Promise<void> {
    const colRef = `/organizations/${this.orgId}/requests/${requestId}/requestReactions/${rxnId}`;
    return this.afsDB.remove(colRef);
  }

  private getRequestList(queryFn?: any): Observable<Request[]> {
    const colRef = `/organizations/${this.orgId}/requests`;
    return this.afsDB.colWithIds$(colRef, queryFn);
  }

  public getNotCompletedRequestsForExecutor(executorId: string): Observable<Request[]> {
    return combineLatest([
      this.getRequestList(ref => {
        return ref
          .where('management.executor.userId', '==', executorId)
          .where('isClosed', '==', false);
      }),
      this.getRequestList((ref) => {
        return ref.where(`management.team.${executorId}.userId`, '==', executorId)
          .where('isClosed', '==', false);
      })
    ]).pipe(
      map(list => uniqBy(flatten(list), 'id')),
    );
  }

  public getAllRequestsForExecutor(executorId: string, services: Service[]): Observable<Request[]> {
    const key = `executor_requests_${executorId}`;
    let obs$: BehaviorSubject<Request[]> = this.storeService.getData(key);

    if (!obs$) {
      obs$ = new BehaviorSubject(undefined);

      const serviceRqs = services.map(s => {
        return this.getRequestList((ref) => {
          ref = ref.where('management.serviceId', '==', s.id);

          if (s.usersCanSeeAllRequests && s.usersCanSeeAllRequests[executorId]) {
            return ref.where('isClosed', '==', false);
          } else {
            return ref.where('management.executorId', '==', executorId);
          }
        });
      });

      combineLatest([
        ...serviceRqs,
        this.getRequestList((ref) => {
          return ref.where(`management.team.${executorId}.userId`, '==', executorId);
        }),
      ]).pipe(
        map(list => uniqBy(flatten(list), 'id')),
      ).subscribe(res => {
        obs$.next(res);
      });

      this.storeService.setData(key, obs$);
    }

    return obs$.asObservable().pipe(
      filter(d => d !== undefined),
    );
  }

  public getRequestsForExecutor(executorId: string): Observable<Request[]> {
    return combineLatest([
      this.getRequestList((ref) => {
        return ref.where('management.executorId', '==', executorId);
      }),
      this.getRequestList((ref) => {
        return ref.where(`management.team.${executorId}.userId`, '==', executorId);
      })
    ]).pipe(
      map(list => uniqBy(flatten(list), 'id')),
    );
  }

  public getRequestsForActivityReporting(startDate: string, workingAreaId?: string, serviceId?: string, municipalityCode?: string, executorId?: string): Observable<Request[]> {
    const colRef = `/organizations/${this.orgId}/requests`;

    return this.afsDB.colWithIds$(colRef, ref => {
      if (startDate) {
        ref = ref.where(`activeIn.months`, 'array-contains', startDate);
      }

      if (municipalityCode) {
        ref = ref.where('area.municipality.code', '==', municipalityCode);
      }

      if (workingAreaId) {
        ref = ref.where('management.workareaId', '==', workingAreaId);
      }

      if (serviceId) {
        ref = ref.where('management.serviceId', '==', serviceId);
      }

      if (executorId) {
        ref = ref.where('management.executorId', '==', executorId);
      }

      return ref;
    });
  }

  public getRequestsForCustomer(customerId: string, areaId?: string): Observable<Request[]> {
    return this.getRequestList((ref) => {
      if (areaId) {
        ref = ref.where('management.workareaId', '==', areaId);
      }

      return ref.where('management.customerId', '==', customerId);
    });
  }

  public getAllCustomerRequests(userId: string): Observable<Request[]> {
    return this.getRequestList((ref) => {
      return ref.where('management.customerId', '==', userId);
    });
  }

  public async updateRequest(request: Request) {
    request.activeIn = this.updateActiveIn(request);

    return this.afsDB.update(`/organizations/${this.orgId}/requests/${request.id}`, request as DocItem);
  }

  public async deleteRequest(request: Request) {
    delete request['__doc'];

    // const res = await this.afsDB.doc(`/organizations/${this.orgId}/requests/${request.id}`).delete();
    const res = await this.apiService.get(`orgs/${this.orgId}/requests/${request.id}/request-deletion`).pipe(take(1)).toPromise();
    this.userService.handleUserExpirySync(request.management.customerId);

    return res;
  }

  public async handleCloseRequest(request: Request, rpt: boolean): Promise<ApiResponse<void>> {
    const role: Role = this.userService.getCurrentUserRole();
    request.activeIn = this.updateActiveIn(request);

    return this.apiService.post<void>(`requests/${request.id}/actions/close?role=${role.roleType}`,
      { repeat: rpt, request }).pipe(take(1)).toPromise();
  }

  private updateActiveIn(request: Request): RequestActiveTimeline {
    const activeIn: RequestActiveTimeline = { years: [], months: [] };

    // side update isClosed
    request.isClosed = request.status === RequestStatus.adminReady || request.status === RequestStatus.closed;

    // iterate all active years and months

    let startDate = new Date();

    if (request.startDate) {
      startDate = new Date(request.startDate);
      if (!isValid(startDate)) {
        startDate = new Date()
      }
    }

    if (!request.startDate) {

      if (request.log) {
        startDate = new Date(request.log.createdAt)
      }

      if (!isValid(startDate)) {
        startDate = new Date()
      }
    }

    let endDate = new Date()
    if (!request.endDate || !isValid(new Date(request.endDate))) {
      endDate = new Date()
    } else {
      endDate = new Date(request.endDate)
    }

    if (isValid(startDate)) {
      let cursor = startDate;

      while (true) {
        if (isBefore(cursor, endDate) || isEqual(cursor, endDate)) {
          const date = startOfMonth(cursor);
          const [year, month] = [format(date, 'YYYY'), format(date, 'YYYY-MM')];

          activeIn.years.push(Number(year));
          activeIn.months.push(month);

          cursor = addMonths(cursor, 1);
        } else {
          break;
        }
      }
      activeIn.years = activeIn.years.filter((value: any, index: any, array: any[]) => array.indexOf(value) === index);
      return activeIn;
    }
  }

  public createRequest(request: Request): Promise<string> {
    return new Promise(async (resolve) => {
      request.id = request.id || this.afsDB.getNewId();
      request.activeIn = this.updateActiveIn(request);

      await this.afsDB.setDoc(`/organizations/${this.orgId}/requests/${request.id}`, this.timeStamp.parseStringToDate(request));

      // handle reporting api call 
      // The api end-point was deleted
      // const role: Role = this.userService.getCurrentUserRole();
      // this.apiService.post<void>(`requests/${request.id}/new?role=${role.roleType}`,
      //   {}).pipe(take(1)).subscribe(res => {
      //     console.log(res);
      //   });

      this.userService.handleUserExpirySync(request.management.customerId);

      resolve(request.id);
    });
  }

  public syncExpiredRequests(serviceId: string): Promise<any> {
    // delay to ensure db update are sync before making api call
    return this.apiService.post<void>(`services/${serviceId}/expiry-options/sync`, {}).pipe(take(1)).toPromise();
  }

  public searchRequests(query: ISearchRequestsQuery, lastDoc?: any): Observable<Request[]> {
    const ref = `/organizations/${this.orgId}/requests`;

    if (query.search) {
      const where: [string, string, any][] = [];
      where.push(['management.workareaId', '==', query.workareaId]);

      if (query.serviceId) {
        where.push(['management.serviceId', '==', query.serviceId]);
      }

      if (query.municipality) {
        where.push(['area.municipality.code', '==', query.municipality.code]);
      }

      if (query.coordinatorId || query.coordinatorId === null) {
        where.push(['management.coordinatorId', '==', query.coordinatorId]);
      }

      if (query.executorId || query.executorId === null) {
        where.push(['management.executorId', '==', query.executorId]);
      }

      if (query.customerId || query.customerId === null) {
        where.push(['management.customerId', '==', query.customerId]);
      }

      if (query.status) {
        where.push(['status', '==', query.status]);
      }

      if (query.district) {
        where.push(['area.district.text', '==', query.district]);
      }

      if (query.neighborhood) {
        where.push(['area.neighbourhood.text', '==', query.neighborhood]);
      }

      if (query.activeinYear) {
        where.push([`activeIn.years`, 'array-contains', query.activeinYear]);
      }
      if (query.isClosed != null) {
        where.push(['isClosed', '==', query.isClosed]);
      }

      // const order = ['log.createdAt', 'asc'];

      return new Observable((observer) => {
        this.searchService.searchCollectionIndex<Request>({
          searchText: query.search,
          ref: ref,
          limit: query.limit || 200,
          where: where,
          // order
        }).then(requests => {
          observer.next(requests);
        });
      });
    } else {
      return this.getRequests(query, lastDoc);
    }
  }

  public getAllRequests(): Observable<Request[]> {
    const ref = `/organizations/${this.orgId}/requests`;
    return this.afsDB.colWithIds$(ref);
  }

  public getRequestById(requestId: string): Observable<Request> {
    const ref = `/organizations/${this.orgId}/requests/${requestId}`;
    return this.afsDB.docWithId$(ref);
  }

  private getRequestsQueryRef(queryRef: any, query: ISearchRequestsQuery, lastDoc?: any) {
    let refObj = queryRef;
    let shouldPaginate = true;

    if (query.workareaId && query.serviceId !== '-1') {
      refObj = refObj.where('management.workareaId', '==', query.workareaId);
      shouldPaginate = true;
    }

    if (query.serviceId && query.serviceId !== '-1') {
      refObj = refObj.where('management.serviceId', '==', query.serviceId);
      shouldPaginate = true;
    }
    if (query.isClosed != null) {
      refObj = refObj.where('isClosed', '==', query.isClosed);
      shouldPaginate = true;
    }

    if (query.municipality) {
      refObj = refObj.where('area.municipality.code', '==', query.municipality.code);
      shouldPaginate = false;
    }

    if (query.neighborhood) {
      refObj = refObj.where('area.neighbourhood.code', '==', query.neighborhood);
      shouldPaginate = false;
    }

    if (query.district) {
      refObj = refObj.where('area.district.code', '==', query.district);
      shouldPaginate = false;
    }

    if (query.coordinatorId || query.coordinatorId === null) {
      refObj = refObj.where('management.coordinatorId', '==', query.coordinatorId);
      shouldPaginate = false;
    }

    if (query.executorId || query.executorId === null) {
      refObj = refObj.where('management.executorId', '==', query.executorId);
      shouldPaginate = false;
    }

    if (query.customerId || query.customerId === null) {
      refObj = refObj.where('management.customerId', '==', query.customerId);
      shouldPaginate = false;
    }

    if (query.status) {
      refObj = refObj.where('status', '==', query.status);
      shouldPaginate = false;
    }

    if (query.activeinYear) {
      refObj = refObj.where(`activeIn.years`, 'array-contains', query.activeinYear);
      shouldPaginate = false;
    }

    if (query.forExport) {
      shouldPaginate = false;
    }

    if (shouldPaginate) {
      if (!query.noSort) refObj = refObj.orderBy('status', 'asc').limit(query.limit || 30);

      if (lastDoc) {
        refObj = refObj.startAfter(lastDoc);
      }
    } else {
      refObj = refObj.limit(query.limit || 100);
    }

    if (query.referredBy) {
      refObj = refObj.where('referredBy', '==', query.referredBy);
    }

    if (query.result) {
      refObj = refObj.where('result', '==', query.result);
    }

    return refObj;
  }

  public getExpiringRequests(workareaId?: string, serviceId?: string): Observable<Request[]> {
    return this.getRequestList((refV) => {
      let ref = refV.where('expiryDate', '>=', new Date(0))
        .orderBy('expiryDate', 'asc');

      if (workareaId) {
        ref = ref.where('management.workareaId', '==', workareaId);
      }

      if (serviceId) {
        ref = ref.where('management.serviceId', '==', serviceId);
      }

      return ref.limit(100);
    });
  }

  public getOpenRequests(workareaId?: string, serviceId?: string): Observable<Request[]> {
    const col = `/organizations/${this.orgId}/requests`;

    const aaa = this.afsDB.colWithIdsNoCache(col, (refV) => {
      let ref = refV.where('isClosed', '==', false);

      if (workareaId) {
        ref = ref.where('management.workareaId', '==', workareaId);
      }

      if (serviceId) {
        ref = ref.where('management.serviceId', '==', serviceId);
      }

      return ref;
    });

    return from(aaa) as any;
  }

  public getRequests(query: ISearchRequestsQuery, lastDoc?: any): Observable<Request[]> {
    const ref = `/organizations/${this.orgId}/requests`;

    return this.afsDB.colWithIds$(ref, (queryRef) => {
      return this.getRequestsQueryRef(queryRef, query, lastDoc);
    }, true);
  }

  public getRequestsNoCache(query: ISearchRequestsQuery, lastDoc?: any): Promise<Request[]> {
    const ref = `/organizations/${this.orgId}/requests`;

    return this.afsDB.colWithIdsNoCache(ref, (queryRef) => {
      return this.getRequestsQueryRef(queryRef, query, lastDoc);
    });
  }

  public getOpenRequestsForUser(userId: string, isComplete?: boolean, userViewMode?: string) {
    const where: Where[] = [];
    const colQuery: Observable<Request[]>[] = [];
    const ref = `/organizations/${this.orgId}/requests`;

    colQuery.push(
      this.afsDB.colWithIdsNew$<Request>(ref, () => {
        where.push(['isClosed', '==', isComplete]);
        if (userViewMode === 'customer') {
          where.push(['management.customerId', '==', userId]);
        } else {
          where.push(['management.executorId', '==', userId]);
        }
        return where;
      })
    )
    if (userViewMode == 'employee') {
      colQuery.push(this.afsDB.colWithIdsNew$(ref, () => {
        return [['isClosed', '==', isComplete],
        [`management.team.${userId}.userId`, '==', userId]];
      }))
    }

    return combineLatest(colQuery);
  }

  public async getCountForUser(userId: string, isComplete: boolean, userViewMode: string) {
    const ref = `/organizations/${this.orgId}/requests`;
    const where: Where[] = [];
    return this.afsDB.getCounts(ref, () => {
      where.push(['isClosed', '==', isComplete]);
      if (userViewMode === 'customer') {
        where.push(['management.customerId', '==', userId]);
      } else {
        where.push(['management.executorId', '==', userId]);
      }
      return where;
    })
  }

  public getRequestCount(query: RequestFilter) {
    const ref = `/organizations/${this.orgId}/requests`;
    const where: Where[] = [];
    return this.afsDB.getCounts(ref, () => {
      if (query.workingAreaFilter && query.workingAreaFilter !== '-1') {
        where.push(['management.workareaId', '==', query.workingAreaFilter]);
      }
      if (query.serviceFilter && query.serviceFilter !== '-1') {
        where.push(['management.serviceId', '==', query.serviceFilter]);
      }
      return where;
    });
  }

  public getContactPersonRequest(contactPersonId?: string) {
    return this.getRequestList((ref) => ref.where('referredByContactPerson.userId', '==', contactPersonId))
  }

  public getRequestCountForUser(userId: string, serviceId: string) {
    const ref = `/organizations/${this.orgId}/requests`;
    const where: Where[] = [];
    return this.afsDB.getCounts(ref, () => {
      where.push(['management.customerId', '==', userId], ['management.serviceId', '==', serviceId]);
      return where
    })
  }

  public getRequestForExport(workingAreaId: string, serviceId: string) {
    const ref = `/organizations/${this.orgId}/requests`;
    const where: Where[] = [];

    return this.afsDB.ColWithIdsNoCacheNew<Request>(ref, () => {
      if (workingAreaId) where.push(['management.workareaId', '==', workingAreaId]);
      if (serviceId) where.push(['management.serviceId', '==', serviceId]);
      return where;
    }
    )
  }

  public getRequestsNew(query: RequestFilter) {
    const ref = `/organizations/${this.orgId}/requests`;
    const constrainOptions: Partial<QueryConstraintOptions<Request>> = {};

    constrainOptions.orderBy = [{ field: 'status', val: 'asc' }, { field: 'log.createdAt', val: 'desc' }]
    if (query.limit) {
      constrainOptions.limit = query.limit;
    }
    if (query.workingAreaFilter && query.workingAreaFilter !== '-1') {
      return this.afsDB.colWithIdsNew$<Request>(ref, () => {
        return this.getFilters(query);
      }, constrainOptions, true
      )
    }
    return of<Request[]>([])
  }

  public getRequestsFromIds(requestIds: string[]): Observable<Request[]> {
    return this.afsDB.docsFromId$(
      `/organizations/${this.orgId}/requests`,
      requestIds
    );
  }

  public getRequestsByFilters(query: RequestFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
    const ref = `/organizations/${this.orgId}/requests`;
    const options: Partial<QueryConstraintOptions<Request>> = {};

    return this.afsDB.colWithIdsNew$<Request>(ref, () => {

      if (lastOrFirstRef) {
        options.orderBy = [{ field: 'status', val: 'asc' }, { field: 'log.createdAt', val: 'desc' }]
        if (isReloadedContext) {
          options.startAt = lastOrFirstRef;
          options.limit = limit || 30;
        } else {
          if (isForwardNavigation) {
            options.startAfter = lastOrFirstRef;
            options.limit = limit || 30;
          } else {
            options.endBefore = lastOrFirstRef;
            options.limitToLast = limit || 30;
          }
        }
      } else {
        options.limit = limit || 30
      }
      return this.getFilters(query);
    }, options, true)
  }

  public searchRequestsNew(query: RequestFilter): Observable<Request[]> {
    let ref = `/organizations/${this.orgId}/requests`;
    if (query.textFilter) {
      let where = this.getFilters(query);

      return new Observable((observer) => {
        this.searchService.searchCollectionIndex<Request>({
          searchText: query.textFilter,
          ref: ref,
          where: where,
          // order
        }).then(requests => {
          observer.next(requests);
        });
      });
    }
  }

  public getAllRequestsByFIlter(query: RequestFilter) {
    const ref = `/organizations/${this.orgId}/requests`;
    return this.afsDB.colWithIdsNew$<Request>(ref, () => {
      return this.getFilters(query);
    })
  }


  private getFilters(query: RequestFilter) {
    const where: Where[] = [];
    where.push(['management.workareaId', '==', query.workingAreaFilter]);

    if (query.serviceFilter && query.serviceFilter !== '-1') {
      where.push(['management.serviceId', '==', query.serviceFilter])
    }
    if (query.executorFilter && query.executorFilter !== '-1') {
      where.push(['management.executorId', '==', query.executorFilter])
    }
    if (query.coordinatorFilter && query.coordinatorFilter !== '-1') {
      where.push(['management.coordinatorId', '==', query.coordinatorFilter])
    }
    if ((query.activeInYearFilter && query.activeInYearFilter !== '-1') && !query.activeInMonthFilter?.length) {
      where.push(['activeIn.years', 'array-contains', query.activeInYearFilter])
    }
    if (query.activeInMonthFilter && query.activeInMonthFilter.length) {
      const months = query.activeInMonthFilter.filter(m => !!m).map(month => {
        const year = query.activeInYearFilter !== '-1' ? query.activeInYearFilter : getYear(new Date);
        return `${year}-${padStart(month.toString(), 2, '0')}`;
      })
      where.push(['activeIn.months', 'array-contains-any', months])
    }
    if (query.kindFilter && query.kindFilter !== '-1') {
      where.push(['requestKind', '==', query.kindFilter]);
    }
    if (query.productFilter && query.productFilter !== '-1') {
      where.push(['productCode', '==', query.productFilter])
    }

    if (query.statusFilter) {
      where.push(['status', '==', query.statusFilter]);
    }

    if (query.municipalityFilter) {
      where.push(['area.municipality.code', '==', query.municipalityFilter.code]);
    }
    if (query.districtFilter && query.districtFilter !== '-1') {
      where.push(['area.district.code', '==', query.districtFilter]);
    }

    if (query.neighborhoodFilter && query.neighborhoodFilter !== '-1') {
      where.push(['area.neighbourhood.code', '==', query.neighborhoodFilter]);
    }

    if (query.referredByFilter && query.referredByFilter !== '-1') {
      where.push(['referredBy', '==', query.referredByFilter])
    }

    if (query.resultFilter && query.resultFilter !== '-1') {
      where.push(['result', '==', query.resultFilter])
    }

    if (query.publishedFilter) {
      where.push(['published', '==', query.publishedFilter]);
    }
    if (query.signalFilter) {
      where.push(['activity.budgetSignal', '==', query.signalFilter])
    }
    if (query.overDueFilter) {
      where.push(['activity.hasOverDueActivity', '==', query.overDueFilter])
    }

    if (query.referredByInstitution) {
      where.push(['referredByInstitution.id', '==', query.referredByInstitution.id]);
    }

    if (query.referredByContactPerson) {
      where.push(['referredByContactPerson.userId', '==', query.referredByContactPerson.userId]);
    }

    return where;
  }

  public getCustomerRequests(customerId: string, serviceId?: string, workingAreaId?: string, onlyOpen?: boolean) {
    const where: Where[] = [['management.customerId', '==', customerId]];
    return this.afsDB.ColWithIdsNoCacheNew<Request>(`/organizations/${this.orgId}/requests`, () => {
      if (serviceId) {
        where.push(['management.serviceId', '==', serviceId]);
      }
      if (workingAreaId) {
        where.push(['management.workareaId', '==', workingAreaId]);
      }
      if (onlyOpen) {
        where.push(['isClosed', '==', false]);
      }
      return where;
    })
  }

  public calculateRequestBudgetPerWeek(request: Request, activities: Activity[], weekBudget: number) {
    if (request.budget && request.productCode && request?.activity?.unitKind) {
      let unitSpent = this.sumTotalMinutes(activities, request);
      switch (request.activity.unitKind) {
        case UnitTypes.hours: unitSpent = unitSpent / 60
          break;
        case UnitTypes.days: unitSpent = unitSpent / 480
          break;
        case UnitTypes.dayparts: unitSpent = unitSpent / 240
          break;
        default:
          console.log('No match for the UnitType')
          break;
      }
      return parseFloat((weekBudget - unitSpent).toFixed(2));
    }
    return null;
  }

  // Sum total min spent for the given request
  private sumTotalMinutes(activities: Activity[], request: Request) {
    let totalMinutes = 0;
    activities.forEach((activity) => {
      if (request.id == activity.requestId) {
        totalMinutes += activity.minutes
      }
    });
    return totalMinutes;
  }

  // Migration for the activity.lastActivityCreatedAt prop
  public async migrationForRequest(orgId: string) {
    const requestsGroupQuery = await this.afsDB.colWithIdsNoCache<Request>(`organizations/${orgId}/requests`, ref => {
      return ref.orderBy('activity.lastActivityCreatedAt', 'desc').limit(200);
    })

    this.afsDB.batchUpdate(requestsGroupQuery as DocItem[], `organizations/${orgId}/requests`)
  }

  public getAddressDistance(body: IObjectMap<any>) {
    return this.apiService.post('address/distance-matrix', body).pipe(take(1)).toPromise();
  }

  public getRepeatedRequests(sequenceId: string) {
    const colRef = `/organizations/${this.orgId}/requests`;
    return this.afsDB.colWithIdsNew$(colRef, () => {
      return [['sequenceId', '==', sequenceId]];
    });
  }

  public getRequestAndServiceByInstitutionId(institutionId: string): Observable<[Request[] | null, Service[] | null]> {
    return this.getRequestList(ref => ref.where('referredByInstitution.id', '==', institutionId))
      .pipe(map(requests => {
        const serviceIds = uniq(requests.map(req => req.management.serviceId).filter(id => !!id));
        return combineLatest([
          requests.length ? of(requests) : [null],
          requests.length ? this.afsDB.docsFromIdNew$<Service>(`organizations/${this.orgId}/services`, serviceIds) : [null]
        ])
      })).pipe(mergeAll())
  }
}
