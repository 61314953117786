import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { isEqual, cloneDeep } from 'lodash';
import { IObjectMap } from '@shared/interface';

export interface DataTableMetaData {
    checked?: boolean;
    disabled?: boolean;
    originalData?: IObjectMap<any>;
}

export interface ITableDataValue {
    title: any;
    subtitle: any;
    width: string;
}

export interface ITableData {
    [key: string]: ITableDataValue | any;
    _metadata?: DataTableMetaData;
}

export interface RowBtnMenuOption {
    icon?: string;
    color?: string;
    text?: string;
}

export interface IDataTableConfig {
    data: ITableData[];
    displayProperties: string[];
    rowOptions: RowOptions[];
    headers?: IObjectMap<string>;
    allowSelection?: boolean;
    displayHeaders?: boolean;
    propertyWithImage?: string;
    noImageDefaultImage?: boolean;
    rowButton?: RowBtnMenuOption;
    actionButtonLabel?: string;
    highlightStatuses?: boolean;
    showTooltip?: boolean;
    createMenuOptions?: (rowData: any) => RowOptions[];
}

export interface RowOptions {
    id: string;
    title: string;
    icon: string;
    hidden?: boolean;
    disabled?: boolean;
}

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    public configOptions: IDataTableConfig;

    public displayProperties: string[] = [];
    // public rowOptions: RowOptions[] = [];

    @Output()
    public onSelectRowAction: EventEmitter<any> = new EventEmitter();

    @Output()
    public onSubmit: EventEmitter<any> = new EventEmitter();

    @Output()
    public onSelectRow: EventEmitter<any> = new EventEmitter();

    @Output()
    public rowSelectionChange: EventEmitter<ITableData> = new EventEmitter();

    @Output()
    public rowMenuBtnClicked: EventEmitter<ITableData> = new EventEmitter();

    @Output()
    public rowMenuOptionBtnClicked: EventEmitter<ITableData> = new EventEmitter();

    public selection = new SelectionModel<any>(true, []);
    public matTableDataSource = new MatTableDataSource<any>();

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.selection.changed.subscribe(payload => {
            // if (payload.added.length > 0) {
            //     this.onSelectRow.emit(payload.added[0]);
            // }

            payload.added.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    item._metadata.checked = true;
                    this.rowSelectionChange.emit(item);
                }
            });

            payload.removed.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    item._metadata.checked = false;
                    this.rowSelectionChange.emit(item);
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.configOptions && this.isNewChange(changes.configOptions)) {
            this.displayProperties = this.configOptions.displayProperties.slice();
            this.matTableDataSource.data = this.configOptions.data;

            // apply selection if specified
            this.matTableDataSource.data.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    if (item._metadata.checked) {
                        this.selection.select(item);
                    } else {
                        this.selection.deselect(item);
                    }
                }
            });

            if (this.configOptions.rowOptions.length > 0) {
                this.displayProperties.push('_actions_');
            }

            if (this.configOptions.rowButton) {
                this.displayProperties.push('_btn_menu_option');
            }
        }
    }

    private isNewChange(change: any) {
        const prev = change.previousValue;
        const current = change.currentValue;

        return !isEqual(prev, current);
    }

    /* A nice lodash based method for detecting changes between two objects
    changes(object, base) {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? this.changes(value, base[key]) : value;
            }
        });
    }*/

    /** Whether all filtered rows are selected. */
    isAllFilteredRowsSelected() {
        return this.matTableDataSource.filteredData.every(data => this.selection.isSelected(data));
    }

    /** Whether the selection it totally matches the filtered rows. */
    isMasterToggleChecked() {
        return (
            this.selection.hasValue() &&
            this.isAllFilteredRowsSelected() &&
            this.selection.selected.length >= this.matTableDataSource.filteredData.length
        );
    }

    /**
     * Whether there is a selection that doesn't capture all the
     * filtered rows there are no filtered rows displayed.
     */
    isMasterToggleIndeterminate() {
        return (
            this.selection.hasValue() &&
            (!this.isAllFilteredRowsSelected() || !this.matTableDataSource.filteredData.length)
        );
    }

    /** Selects all filtered rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isMasterToggleChecked()) {
            this.selection.clear();
        } else {
            this.matTableDataSource.filteredData.forEach(data => {
                if (!data._metadata || !data._metadata.disabled) {
                    this.selection.select(data);
                }
            });
        }
    }

    public rowButtonMenuClicked(row: ITableData) {
        this.rowMenuBtnClicked.emit(row);
    }

    public handleRowSelect(row: ITableData) {
        if (this.configOptions.allowSelection) {
            this.selection.toggle(row);
        }

        if (this.onSelectRow) {
            this.onSelectRow.emit(row);
        }
    }

    selectRowAction(
        option: RowOptions,
        row: any,
        rowOptions: RowOptions[],
        currentIndex: number
    ): void {
        this.onSelectRowAction.emit({ option, data: row, rowOptions, currentIndex });
    }

    actionButtonClick() {
        this.onSubmit.emit(this.selection.selected);
    }

    createMenuData(row: any): { menuItems: RowOptions[] } {
        const data = Object.create(null);
        data.menuItems = [];
        if (this.configOptions.createMenuOptions instanceof Function) {
            data.menuItems = this.configOptions.createMenuOptions(row);
        } else {
            data.menuItems = cloneDeep(this.configOptions.rowOptions || []);
        }
        return data;
    }

    public rowOptionMenuClicked(row: ITableData) {
        this.rowMenuOptionBtnClicked.emit(row);
    }

    protected statusNumColor(data: any) {
        let style = 'status-tmpl';
        if (data?.innerColor) style += ' text-white';
        else style += ` status-color-${data.val}`;
        return style;
    }
}
