
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostcodeAddress } from '@shared/model';

// const API_KEY = 'kxVzm7vOqc63vfPJW8aCHHTdOdM5ZoP2yGfG8q77'; free 100 per day
const API_KEY = 'ACsoGKJzbQ4W3JedkPTFN8os11iULxcs3fBb2Ung'; // 1000 per day

@Injectable({
  providedIn: 'root',
})
export class PostcodeService {
  constructor(private http: HttpClient) { }

  public getAddress(postcode: string, number: number): Promise<PostcodeAddress> {
    const options: any = {
      headers: {
        'x-api-key': API_KEY,
        'accept': 'application/hal+json'
      }
    };

    return <Promise<PostcodeAddress>>this.http.get(
      `https://api.postcodeapi.nu/v2/addresses/?postcode=${postcode}&number=${number}`,
      options
    ).pipe(take(1)).toPromise().then((data: any) => <PostcodeAddress>data);
  }
}
