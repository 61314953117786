<div *ngIf="!filters" fxLayoutAlign="center center" style="padding: 30% 0;">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>

<div *ngIf="filters">
  <div fxLayout="row" fxLayoutAlign="space-between" class="p-2">
    <div *ngIf="allRequestsOptions.showAllRequests" fxLayout="column">
      <mat-chip-listbox aria-label="Area" *ngIf="workingAreaChips.length > 0">
        <ng-container *ngFor="let area of workingAreaChips | sort:'name'">
          <mat-chip-option *ngIf="workingAreaStats[area.id] > 0" color="primary"
            [selected]="filters.workingAreaId === area.id" (click)="onClickWorkingAreaChip(area)">
            {{area.name}}&nbsp;<span>({{workingAreaStats[area.id]}})</span>
          </mat-chip-option>
        </ng-container>
      </mat-chip-listbox>
      <mat-chip-listbox aria-label="Services" *ngIf="serviceChips.length > 0">
        <ng-container *ngFor="let service of serviceChips | sort:'name'">
          <mat-chip-option *ngIf="serviceStats[service.id] > 0" color="accent"
            [selected]="service.id === filters.serviceId" (click)="onClickServiceChip(service)">
            {{service.name}}&nbsp;<span>({{serviceStats[service.id]}})</span>
          </mat-chip-option>
        </ng-container>
      </mat-chip-listbox>
    </div>

    <div *ngIf="request && !allRequestsOptions.showAllRequests">{{request.title}}</div>

    <div *ngIf="request">
      <mat-slide-toggle [(ngModel)]="allRequestsOptions.showAllRequests"
        [disabled]="allRequestsOptions.canSeeAllActivities"
        (ngModelChange)="showAllRequestsChanged()">{{labels.all_requests}}
        ({{allRequestsOptions.allRequestCount}})</mat-slide-toggle>
    </div>
  </div>

  <hr>

  <div class="p-2">
    <div *ngIf="activities.length === 0 && !fetchingMore" style="height: 300px" fxLayout="column"
      fxLayoutAlign="center center">
      {{labels.no_items_found}}
    </div>

    <mat-nav-list fxLayout="column" fxLayoutGap="10px" *ngIf="activities.length > 0">
      <app-activity-item-list [customerSelectable]="isGroup" [lastReadDate]="lastReadDate"
        (onDeleteActivity)="deleteActivity($event)" (onEditActivity)="editActivity($event)" [users]="creatorsMap"
        (onDeleteActivityReply)="deleteActivityReply($event.reply, $event.item)" (onAddReply)="addReply($event)"
        (onEditReply)="editReply($event.reply, $event.item)" [activities]="activities" [editMode]="editMode">
      </app-activity-item-list>

      <div class="p-3" fxLayout="row" fxLayoutAlign="center center">
        <button [disabled]="fetchingMore" *ngIf="lastActivityRef" (click)="fetchActivities()" mat-stroked-button
          color="primary">{{fetchingMore ? labels.please_wait : labels.fetch_more}}</button>
      </div>
    </mat-nav-list>
  </div>
</div>