import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportUsersService, LabelService, UserPromptsService } from '@core/services';
import { User } from '@models/index';
import { getMonth } from 'date-fns';

@Component({
  selector: 'app-birthday-export',
  templateUrl: './birthday-export.component.html',
  styleUrls: ['./birthday-export.component.scss']
})
export class BirthdayExportComponent implements OnInit {

  public labels: any = {};
  public months: { id: number; name: string }[];
  public selectedMonth: string;
  private users: User[];
  private usersToExport: User[];

  constructor(
    private labelService: LabelService,
    private exportUserService: ExportUsersService,
    @Inject(MAT_DIALOG_DATA) public data: User[],
    private userPromptsService: UserPromptsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.users = this.data;
    this.labels = (await this.labelService.getLabels('app-birthday-export')).data;
    this.months = await this.labelService.getMonthNames();
  }

  public filterUsers(birthdayMonth: number) {
    this.usersToExport = this.users.filter(user => {
      if (user.birthday) {
        return getMonth(user.birthday) === birthdayMonth;
      }
      return false;
    });
  }

  public async exportUsers() {
    if (this.usersToExport.length) {
      this.exportUserService.makeUserExport(this.usersToExport);
      return this.userPromptsService.showToast(this.labels.users_exported);
    }
    this.userPromptsService.showToast(this.labels.no_user_to_export);
  }

}
