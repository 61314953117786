<div fxLayout="column" fxLayoutGap="10px">
    <div *ngFor="let item of activities; trackBy:trackByFn" fxLayout="column" fxLayoutGap="12px">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxFlex="1 1 auto" class="activity-item">
                <div fxFlex="1 1 80px" fxLayout="column" class="activity-item__date"
                    [ngClass]="{'unread': isUnRead(item.activity.date)}">
                    <span class="item_title">
                        {{getLocaleDate(item?.activity.date || item?.activity?.log.createdAt)}}</span>
                    <span class="item_subtitle">
                        {{getLocaleDate(item?.activity.date || item?.activity?.log.createdAt, true)}}</span>
                </div>

                <div fxFlex="stretch" class="activity-item__content">
                    <div fxLayout="column" fxFlex="1 1 100%">
                        <div fxLayoutAlign="start center" fxLayoutAlign.xs="flex-start" fxLayout="row"
                            fxLayout.xs="column-reverse">
                            <div fxFlex="stretch">
                                <span class="item_title" fxLayout="row" fxLayoutGap="5px">
                                    <span *ngIf="item.activity.amount">{{item.activity.amount | currency:'€'}}</span>
                                    <span>{{item.activity.title}}</span>
                                    <span *ngIf="item.activity.minutes > 0">({{labels._translate('time_in_minutes',
                                        {minutes: item.activity.minutes})}})</span>
                                </span>
                            </div>
                            <div fxLayoutAlign="start center" fxLayoutAlign.xs="end center"
                                *ngIf="getUser(item.activity.log.createdBy) as creator" fxLayout="row">
                                <span class="item_title" style="white-space: nowrap;">
                                    {{creator.fullname}}
                                </span>
                                <span *ngIf="editMode">
                                    <button [disabled]="currentUserId !== creator.id && !canReply && orgAdmin === false"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Expandable menu"
                                        (click)="rowOptionClicked(item)">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button *ngIf="canReply" mat-menu-item (click)="addReply(item.activity)">
                                            <mat-icon>reply</mat-icon>
                                            <span>{{labels.reply}}</span>
                                        </button>
                                        <button [disabled]="rowOptionBtn.disabled"
                                            mat-menu-item (click)="moveActivity()">
                                            <mat-icon>menu_open</mat-icon>
                                            <span>{{rowOptionBtn.label}}</span>
                                        </button>
                                        <button [disabled]="currentUserId !== creator.id && hasOrgAdminRole === false"
                                            mat-menu-item (click)="editActivity(item.activity)">
                                            <mat-icon>edit</mat-icon>
                                            <span>{{labels.edit}}</span>
                                        </button>
                                        <button [disabled]="currentUserId !== creator.id && hasOrgAdminRole === false"
                                            mat-menu-item (click)="deleteActivity(item.activity)">
                                            <mat-icon>delete</mat-icon>
                                            <span>{{labels.delete}}</span>
                                        </button>
                                    </mat-menu>
                                </span>
                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutGap="5px">
                            <span (click)="openAttachment(attach)" class="attachment_link"
                                *ngFor="let attach of item.activity.attachments">
                                {{attach.filename}}
                            </span>
                        </div>

                        <div class="carriage_returns" style="padding-top: 10px;">
                            {{item.activity.description}}
                        </div>

                        <div class="activity_item_footer" fxLayout="row" fxFlex="1 1 100%"
                            fxLayoutAlign="space-between">
                            <div fxFlex="1 1 49%" fxLayoutAlign="space-between">
                                <span *ngIf="activityKinds[item.activity.kind]">
                                    {{labels[activityKinds[item.activity.kind]]}}
                                </span>
                            </div>

                            <span fxLayout="row nowrap" fxFlex="1 1 49%" fxLayoutAlign="end  end">
                                <span *ngIf="item.workingArea && item.service && !customerSelectable">
                                    {{item.workingArea.name}} -
                                    {{item.service.name}}</span>

                                <span class="cursor_pointer text-underline" (click)="openUserModal(item)"
                                    *ngIf="customerSelectable && getUser(item.activity.userId) as customer">
                                    {{customer.fullname}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let reply of item.activity.replies" class="activity-reply-item" fxLayout="row"
                fxLayoutAlign="end center">
                <div fxFlex="1 1 80px" fxLayout="column"> </div>
                <div fxFlex="stretch" class="activity-reply-content">
                    <div fxLayout="column" fxFlex="1 1 100%" fxLayoutGap="10px">
                        <div fxLayoutAlign="start center" fxLayout="row">
                            <div fxFlex="stretch">
                                <span class="fs-0-8">{{timeAgo[reply.id]}}</span>
                            </div>
                            <div fxLayoutAlign="start center" *ngIf="getUser(reply.log.createdBy) as replier"
                                fxLayout="row">
                                <span class="" style="white-space: nowrap;">
                                    {{replier.fullname}}
                                </span>
                                <span *ngIf="editMode">
                                    <button [disabled]="currentUserId !== replier.id && hasOrgAdminRole === false"
                                        mat-icon-button [matMenuTriggerFor]="replyMenu" aria-label="Expandable menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #replyMenu="matMenu">
                                        <button mat-menu-item (click)="editReply(reply, item.activity)">
                                            <mat-icon>edit</mat-icon>
                                            <span>{{labels.edit}}</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteActivityReply(reply, item.activity)">
                                            <mat-icon>delete</mat-icon>
                                            <span>{{labels.delete}}</span>
                                        </button>
                                    </mat-menu>
                                </span>
                            </div>
                        </div>

                        <div class="carriage_returns">
                            {{reply.description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>