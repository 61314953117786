var parse = require('../parse/index.js');

/**
 * @category Day Helpers
 * @summary Return the array of dates within the specified range.
 *
 * @description
 * Return the array of dates within the specified range.
 *
 * @param {Date|String|Number} startDate - the first date
 * @param {Date|String|Number} endDate - the last date
 * @param {Number} [step=1] - the step between each day
 * @returns {Date[]} the array with starts of days from the day of startDate to the day of endDate
 * @throws {Error} startDate cannot be after endDate
 *
 * @example
 * // Each day between 6 October 2014 and 10 October 2014:
 * var result = eachDay(
 *   new Date(2014, 9, 6),
 *   new Date(2014, 9, 10)
 * )
 * //=> [
 * //   Mon Oct 06 2014 00:00:00,
 * //   Tue Oct 07 2014 00:00:00,
 * //   Wed Oct 08 2014 00:00:00,
 * //   Thu Oct 09 2014 00:00:00,
 * //   Fri Oct 10 2014 00:00:00
 * // ]
 */
function eachDay(dirtyStartDate, dirtyEndDate, dirtyStep) {
  var startDate = parse(dirtyStartDate);
  var endDate = parse(dirtyEndDate);
  var step = dirtyStep !== undefined ? dirtyStep : 1;
  var endTime = endDate.getTime();
  if (startDate.getTime() > endTime) {
    throw new Error('The first date cannot be after the second date');
  }
  var dates = [];
  var currentDate = startDate;
  currentDate.setHours(0, 0, 0, 0);
  while (currentDate.getTime() <= endTime) {
    dates.push(parse(currentDate));
    currentDate.setDate(currentDate.getDate() + step);
  }
  return dates;
}
module.exports = eachDay;