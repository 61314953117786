import { Address } from './address';
import { DocumentLog } from './documentLog';
import { SocialRelation, SocialRelationKindTypes } from './customer-network';
import { IObjectMap, RatesItem } from '../interface';
import { IArea, SearchIndex } from '.';
import { USER_SEARCH_FIELDS } from '../../users/userSearchFields';
import { cloneDeep, values } from 'lodash';

export const countryDialingCodes = [
  '+31',
  '+234'
];

export class AttachedDoc {
  id?: string;
  url: string;
  ref: string;
  name: string;
  areadId: IObjectMap<boolean>;
  serviceId: IObjectMap<boolean>;
  isEmployeeDoc?: boolean;
  isCustomerDoc?: boolean;
  isAttachmentDoc?: boolean;
  type: string;
  log?: DocumentLog

  constructor() {
    this.areadId = {};
    this.serviceId = {};
  }
}

export class CustomerDetails {
  remarks: string;
  districtCoach: string;
  attachedDocs: IObjectMap<AttachedDoc>;
  fileLink?: string;

  constructor() {
    this.remarks = '';
    this.districtCoach = '';
    this.attachedDocs = {};
    this.fileLink = '';
  }
}

export class ExecutorDetails {
  remarks: string;

  requestsCounts: IObjectMap<number>;

  constructor() {
    this.remarks = '';
    this.requestsCounts = {};
  }
}

export class EmployeeDetails {
  remarks: string;
  offers: string;
  rates: RatesItem;
  defaultBudgetCode: string;
  dateOfEntry: string;
  dateOfLeaving: string;
  fileLink: string;


  constructor() {
    this.remarks = '';
    this.offers = '';
    this.defaultBudgetCode = '';
    this.dateOfEntry = '';
    this.dateOfLeaving = '';
    this.fileLink = '';
  }
}

export class UserPartner {
  partnerRegistration: boolean;
  firstname: string;
  lastname: string;
  birthday: string;
  gender?: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.birthday = '';
  }
}

export class UserContactPerson {
  contactPersonRegistration: boolean
  firstName: string;
  lastName: string;
  phone: string;
  remark: string;
  email: string;
  socialRelationKind?: SocialRelationKindTypes;

  constructor() {
    this.contactPersonRegistration = false
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.remark = '';
    this.socialRelationKind = null;
  }
}

export interface AppointmentReminder {
  oneHourBefore: boolean;
  oneDayBefore: boolean;
}

export interface Notification {
  appointment: AppointmentReminder;
}

export class UserSettings {
  notification?: Notification
  excelMapping?: IObjectMap<IObjectMap<string>>;
  emailFooter?: string;
}

export class NetworkPartnerDetails {
  isNetworkPartner: boolean;
  networkPartnerType: string;

  constructor() {
    this.isNetworkPartner = false;
  }
}

export interface IModuleReservation {
  user: boolean;
  coordinator: boolean;
}

export interface DailyAvailabilitySession {
  available: boolean;
  timeslots: string[];
}

export interface UserDailyAvailability {
  available: boolean;
  morning: DailyAvailabilitySession;
  afternoon: DailyAvailabilitySession;
  evening: DailyAvailabilitySession;
  night: DailyAvailabilitySession;
  remark: string;
}

export interface UserAvailability {
  comment: string;
  availability: UserAvailabilityTypes;
  notAvailableUntil: string;
  days: {
    monday: UserDailyAvailability;
    tuesday: UserDailyAvailability;
    wednesday: UserDailyAvailability;
    thursday: UserDailyAvailability;
    friday: UserDailyAvailability;
    saturday: UserDailyAvailability;
    sunday: UserDailyAvailability;
  }
}

export const defaultUserAvailability = (): UserAvailability => {
  const session: DailyAvailabilitySession = {
    available: false,
    timeslots: [],
  };

  const dfDay: UserDailyAvailability = {
    available: false,
    morning: cloneDeep(session),
    afternoon: cloneDeep(session),
    evening: cloneDeep(session),
    night: cloneDeep(session),
    remark: '',
  };

  const av: UserAvailability = {
    comment: '',
    availability: 1,
    notAvailableUntil: null,
    days: {
      monday: cloneDeep(dfDay),
      tuesday: cloneDeep(dfDay),
      wednesday: cloneDeep(dfDay),
      thursday: cloneDeep(dfDay),
      friday: cloneDeep(dfDay),
      saturday: cloneDeep(dfDay),
      sunday: cloneDeep(dfDay),
    }
  };

  // updates default available periods
  values(av.days).forEach(d => {
    d.afternoon.available = true;
    d.morning.available = true;
  });

  return av;
}

export interface UserRelationItem {
  active: boolean;
  disabled?: boolean;
  canSeeAllRequests?: boolean;
}

export interface UserServiceRelation {
  executor?: UserRelationItem;
  coordinator?: UserRelationItem;
  customer?: UserRelationItem;
}

export interface UserWorkingAreaRelation {
  executor?: UserRelationItem;
  coordinator?: UserRelationItem;
  professional?: UserRelationItem;
  customer?: UserRelationItem;
}

export interface InstitutionContactPersonRelation {
  institutionId: string;
  isAdmin?: boolean;
  remark?: string;
  role?: string;
}

export interface UserRolesConfig {
  isExecutor: boolean;
  isCoordinator: boolean;
  isProfessional: boolean;
  services: IObjectMap<UserServiceRelation>;
  workingAreas: IObjectMap<UserWorkingAreaRelation>;
  isInstitutionContact: boolean;
  institutions: IObjectMap<InstitutionContactPersonRelation>;
  isCustomer: boolean;
}

export interface Membership {
  memberSince?: string;
  // membershipEnd: string;
  membershipKind?: string;
  remark?: string;
  // active: boolean;
}
  
export class User {
  id?: string;
  address: Address;
  firstname: string;
  lastname: string;
  fullname: string;
  birthday: string;
  gender?: string;
  phone: string;
  phoneRemark: string;
  phone2: string;
  phone2Remark: string;
  email: string;
  email2: string;
  emailRemark: string;
  email2Remark: string;
  active: boolean;
  code: string;
  picture: string;
  expiryDate?: Date;
  startExpiryDate?: Date;
  uid: string;
  isOrgAdmin?: boolean;
  isSuperAdmin?: boolean;
  isCoach?: boolean;
  log: DocumentLog;
  searchIndex?: SearchIndex;
  customerDetails: CustomerDetails;
  executorDetails: ExecutorDetails;
  employeeDetails: EmployeeDetails;
  moduleReservations?: IModuleReservation;
  settings: UserSettings;
  area: IArea;
  decedent: boolean;
  usernote: string;
  termsAccepted: boolean;
  addressline: string;
  networkPartner: NetworkPartnerDetails;
  activities?: IObjectMap<boolean>;
  socialRelations: IObjectMap<SocialRelation>; // person id maps to social relation object
  emailAuthorizedPerson?: string;
  activityLastSeenByUsers: IObjectMap<Date>;
  deviceTokens?: IObjectMap<boolean>;
  availability: UserAvailability;
  personalNumber: string;
  roles: UserRolesConfig;
  usersLastSeen: string[];
  userPartner: UserPartner;
  userContactPerson: UserContactPerson;
  userContactPerson2: UserContactPerson;
  membership: Membership;
  mailinglistSubscriptions?: string[];
// The user type has to be any here after lodash extend method
  // See line 257
  constructor(user?: any) {
    this.firstname = '';
    this.lastname = '';
    this.fullname = '';
    this.active = true;
    this.picture = '';
    this.decedent = false;
    this.termsAccepted = false;
    this.userPartner = {
      partnerRegistration: false,
      birthday: '',
      firstname: '',
      lastname: ''
    };
    this.log = new DocumentLog();
    this.address = new Address();
    this.customerDetails = new CustomerDetails();
    this.executorDetails = new ExecutorDetails();
    this.employeeDetails = new EmployeeDetails();
    this.socialRelations = {};
    this.networkPartner = new NetworkPartnerDetails();
    this.log = user?.log ? user.log : null;
    this.moduleReservations = {
      user: false,
      coordinator: false
    };
    this.userContactPerson = new UserContactPerson;
    this.userContactPerson2 = new UserContactPerson;
    this.searchIndex = {
      properties: [...USER_SEARCH_FIELDS],
      index: {}
    };

    this.settings = {
      notification: {
        appointment: {
          // After using lodash to extends the properties the latest properties are in the root body
          oneDayBefore: user?.oneDayBefore !== undefined ? user.oneDayBefore: true,
          oneHourBefore: user?.oneHourBefore !== undefined ? user.oneHourBefore : true
        }
      }
    };
    this.activityLastSeenByUsers = {};
    this.availability = defaultUserAvailability();
    this.mailinglistSubscriptions = [];
    this.roles = {
      isExecutor: false,
      isCoordinator: false,
      isProfessional: false,
      services: {},
      workingAreas: {},
      isInstitutionContact: false,
      institutions: {},
      isCustomer: false,
    };
  }
}

export const UserImportMandatory: IObjectMap<string> = {
  firstname: 'firstname',
  lastname: 'lastname'
};

export const UserImportOptional: IObjectMap<string> = {
  email: 'email',
  phone: 'phone',
  phone2: 'phone2',
  gender: 'gender',
  birthday: 'birthday',
  number: 'number',
  letter: 'letter',
  postalcode: 'postalcode',
  contactPersonFirstName: 'contactPersonFirstName',
  contactPersonLastName: 'contactPersonLastName',
  contactPersonPhone: 'contactPersonPhone',
  contactPersonRemark: 'contactPersonRemark',
  contactPersonEmail: 'contactPersonEmail',
  contactPersonSocialRelationKind: 'contactPersonSocialRelationKind'
};

export enum UserAvailabilityTypes {
  available = 1,
  notAvailable
}